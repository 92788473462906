import React, { useState, useMemo } from "react";
import queries from "../helpers/queries";
import { FiFileText, FiMonitor, FiX, FiEdit } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Select from "react-select";
import Modal from "../Components/modal";
import Table from "../Components/darkBasicTable";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useDimensions from "../Hooks/useDimensions";

const sevenDaysLater = moment().add(7, "days");

const formFields = [
    {
        name: "spaPrice",
        label: "SPA Price",
        type: "numbers",
    },
    {
        name: "rebate",
        label: "Rebate",
        type: "numbers",
    },
    {
        name: "fees",
        label: "Fees",
        type: "numbers",
    },
    {
        name: "cashback",
        label: "Cash Back",
        type: "numbers",
    },
    {
        name: "netPrice",
        label: "Net Price",
        type: "numbers",
    },
    {
        name: "status",
        label: "Status",
    },
    {
        name: "bookingDate",
        label: "Booking Date",
        type: "date",
    },
    {
        name: "unitNumber",
        label: "Unit Number",
    },
    {
        name: "purchaserName",
        label: "Purchaser Name",
    },
    {
        name: "purchaserName2",
        label: "Purchaser Name 2",
    },
    {
        name: "purchaserName3",
        label: "Purchaser Name 3",
    },
    {
        name: "purchaserName4",
        label: "Purchaser Name 4",
    },
    {
        name: "purchaserName5",
        label: "Purchaser Name 5",
    },
];

const commissionFields = [
    {
        name: "agentCommission",
        label: "Agent Commission",
        type: "numbers",
    },
    {
        name: "commissionPercentage",
        label: "Commission Percentage",
        type: "numbers",
    },
    {
        name: "leaderCommission",
        label: "Leader Commission",
        type: "numbers",
    },
    {
        name: "leadercommissionPercentage",
        label: "Leader Commission Percentage",
        type: "numbers",
    },
    {
        name: "primeCommission",
        label: "Prime Leader Commission",
        type: "numbers",
    },
    {
        name: "primeleadercommissionPercentage",
        label: "Prime Commission Percentage",
        type: "numbers",
    },
];

const statusOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Pending Loan",
        value: "pending",
    },
    {
        label: "Cancelled",
        value: "cancel",
    },
    {
        label: "Converted",
        value: "converted",
    },
    // {
    //     label: "Half Converted",
    //     value: "halfconverted",
    // },
    {
        label: "Advanced",
        value: "advanced",
    },
    {
        label: "Release",
        value: "release",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "SignedLo",
        value: "signedLo",
    },
];

const Report = ({ user, location }) => {
    let renderList = [];
    let uplineRenderList = [];
    const [modalData, setModalData] = useState({});
    const [checklist, setChecklist] = useState([]);
    const [uplinechecklist, setUplineChecklist] = useState([]);
    const [agentFastCommList, setAgentFastCommList] = useState([]);
    const [leaderFastCommList, setLeaderFastCommList] = useState([]);
    const [modalState, setModalState] = useState();
    const [changeCommissionStatusModal, setChangeCommissionStatusModal] =
        useState(false);
    const [
        agentBulkChangeCommissionStatusModal,
        setAgentBulkChangeCommissionStatusModal,
    ] = useState(false);
    const [
        bulkChangeCommissionStatusModal,
        setBulkChangeCommissionStatusModal,
    ] = useState(false);
    const [
        changeAgentCommissionStatusModal,
        setChangeAgentCommissionStatusModal,
    ] = useState(false);
    const [typeCommissionStatus, setTypeCommissionStatus] = useState({});
    const [typeReferralCommissionStatus, setTypeReferralCommissionStatus] =
        useState(undefined);

    const [commissionState, setCommissionState] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const size = useDimensions();

    const { errors } = useForm({
        defaultValues: {
            gallery: [{}],
            salesKit: "",
            spaPrice: "",
            rebate: "",
            fees: "",
            cashback: "",
            netPrice: "",
            status: "",
            bookingDate: moment(Date.now()).format("YYYY-MM-DD"),
            unitNumber: "",
            purchaserName: "",
            purchaserName2: "",
            purchaserName3: "",
            purchaserName4: "",
            purchaserName5: "",
            purchaserIc: "",
            purchaserIc2: "",
            purchaserIc3: "",
            purchaserIc4: "",
            purchaserIc5: "",
            purchaserContact: "",
            purchaserContact2: "",
            purchaserContact3: "",
            purchaserContact4: "",
            purchaserContact5: "",
            project: location.state
                ? location.state.project
                    ? location.state.project
                    : undefined
                : undefined,
        },
    });

    const resetModalData = () => {
        setModalState(false);
    };

    const resetCommissionState = () => {
        setCommissionState(false);
    };
    const resetCommissionStatusModal = () => {
        setChangeCommissionStatusModal(false);
        resetStatusCommissionForm();
    };

    const resetBulkCommissionStatusModal = () => {
        setAgentBulkChangeCommissionStatusModal(false);
        resetStatusCommissionForm();
    };

    const resetBulkUplineCommissionStatusModal = () => {
        setBulkChangeCommissionStatusModal(false);
        resetStatusCommissionForm();
    };

    const resetAgentCommissionStatusModal = () => {
        setChangeAgentCommissionStatusModal(false);
        resetStatusCommissionForm();
    };

    const {
        register: registerStatusForm,
        handleSubmit: handleSubmitStatusForm,
        getValues: getValuesStatusCommissionForm,
        reset: resetStatusCommissionForm,
    } = useForm({
        defaultValues: {
            fastComm: undefined,
        },
    });

    const [editCommissionStatus] = useMutation(queries.EDIT_COMMISSION_STATUS);

    const handleEditStatus = async () => {
        try {
            const formValues = getValuesStatusCommissionForm();

            const result = await editCommissionStatus({
                variables: {
                    memberId: modalData ? modalData.memberId : undefined,
                    caseId: modalData
                        ? modalData.nested
                            ? modalData.nested._id
                            : undefined
                        : undefined,
                    fastComm: formValues.fastComm
                        ? parseFloat(formValues.fastComm)
                        : undefined,
                    type: typeCommissionStatus,
                },
            });

            if (result.data) {
                alert("Success");
                setModalData((prev) => ({
                    ...prev,
                    members: result.data.editCommissionStatus.members,
                    sponsorCommissions:
                        result.data.editCommissionStatus.sponsorCommissions,
                    leaderCommissions:
                        result.data.editCommissionStatus.leaderCommissions,
                    ptp1Commissions:
                        result.data.editCommissionStatus.ptp1Commissions,
                    ptp2Commissions:
                        result.data.editCommissionStatus.ptp2Commissions,
                    ptpCommissions:
                        result.data.editCommissionStatus.ptpCommissions,
                }));

                setChangeCommissionStatusModal(false);
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const [editBulkCommissionStatus] = useMutation(
        queries.EDIT_BULK_COMMISSION_STATUS
    );

    const handleEditBulkStatus = async () => {
        try {
            const formValues = getValuesStatusCommissionForm();

            const result = await editBulkCommissionStatus({
                variables: {
                    memberId: modalData ? modalData.memberId : undefined,
                    cases: checklist,
                    fastComm: formValues.fastComm
                        ? parseFloat(formValues.fastComm)
                        : undefined,
                    type: typeCommissionStatus,
                },
            });

            if (result) {
                alert("Success");
                window.location.reload();
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const handleEditLeaderStatus = async () => {
        try {
            const formValues = getValuesStatusCommissionForm();

            const result = await editCommissionStatus({
                variables: {
                    memberId: modalData ? modalData.memberId : undefined,
                    caseId: modalData
                        ? modalData.nested
                            ? modalData.nested._id
                            : undefined
                        : undefined,
                    fastComm: formValues.fastComm
                        ? parseFloat(formValues.fastComm)
                        : undefined,
                    type: typeReferralCommissionStatus,
                },
            });

            if (result.data) {
                alert("Success");
                setModalData((prev) => ({
                    ...prev,
                    members: result.data.editCommissionStatus.members,
                    sponsorCommissions:
                        result.data.editCommissionStatus.sponsorCommissions,
                    leaderCommissions:
                        result.data.editCommissionStatus.leaderCommissions,
                    ptp1Commissions:
                        result.data.editCommissionStatus.ptp1Commissions,
                    ptp2Commissions:
                        result.data.editCommissionStatus.ptp2Commissions,
                    ptpCommissions:
                        result.data.editCommissionStatus.ptpCommissions,
                }));

                setChangeCommissionStatusModal(false);
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const [editBulkUplineCommissionStatus] = useMutation(
        queries.EDIT_BULK_UPLINE_COMMISSION_STATUS
    );

    const handleEditBulkUplineStatus = async () => {
        try {
            const formValues = getValuesStatusCommissionForm();

            const result = await editBulkUplineCommissionStatus({
                variables: {
                    memberId: modalData ? modalData.memberId : undefined,
                    cases: uplinechecklist,
                    fastComm: formValues.fastComm
                        ? parseFloat(formValues.fastComm)
                        : undefined,
                },
            });

            if (result) {
                alert("Success");
                window.location.reload();
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });

    const [filters, setFilters] = useState({
        project: location.state
            ? location.state.project
                ? location.state.project
                : null
            : null,
        dateTo: moment(Date.now()).format("YYYY-MM-DD"),
        dateFrom: moment(Date.now()).subtract(1, "months").format("YYYY-MM-DD"),
        status: {
            label: "All",
            value: "all",
        },
        downline: null,
    });

    const { data: projectsData } = useQuery(queries.FETCH_PROJECTS, {
        fetchPolicy: "network-only",
        variables: {
            limit: 9999,
            page: 1,
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const { data: casesData, loading: casesLoading } = useQuery(
        queries.FETCH_CASES_REPORT,
        {
            fetchPolicy: "network-only",
            variables: {
                limit: paginationState.limit,
                page: paginationState.page,
                by: paginationState.by ? paginationState.by : undefined,
                dir: paginationState.dir ? paginationState.dir : undefined,
                project: filters.project ? filters.project.value : undefined,
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo,
                status: filters.status ? filters.status.value : undefined,
                downline: filters.downline ? filters.downline.id : undefined,
            },
            onCompleted: ({ fetchCasesAgent: { pagination } }) => {
                setIsLoading(false);
                setPaginationState(pagination);
                setAgentFastCommList(
                    casesData.fetchCasesAgent.data.filter(row => {
                        let commStatus = "";
                        row.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                commStatus =
                                    item.fullClaimComm != null
                                        ? "Full Claim"
                                        : item.secondBatchComm != null
                                        ? "Second Batch"
                                        : item.firstBatchComm != null
                                        ? "First Batch"
                                        : item.memberCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.memberCommStatus.fastComm === 50
                                        ? "FastComm-Half"
                                        : item.memberCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                return;
                            }
                        });

                        return (
                            
                            (
                                (commStatus === "-" && row.status === "pending" &&
                                moment(row.bookingDate).isAfter("2025-03-16") &&
                                moment(row.createdAt).isBefore(sevenDaysLater))
                                ||
                                ((commStatus === "-" || commStatus === "FastComm-Full" || commStatus === "FastComm-Half" || commStatus === "None") && ( row.status === "pending" || row.status === "converted") &&
                                moment(row.bookingDate).isAfter("2025-03-09") &&
                                moment(row.bookingDate).isBefore("2025-03-17"))
                            )
                        );
                    })
                );

 
                            
                setLeaderFastCommList(
                    casesData.fetchCasesAgent.data.filter(row => {
                        let commStatus = "";
    
                        row.sponsorCommissions.forEach((item) => {
                            if (item.user._id === user.auth.userId) {
                                commStatus =
                                    item.referralCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.referralCommStatus.fastComm ===
                                          50
                                        ? "FastComm-Half"
                                        : item.referralCommStatus.fastComm ===
                                        0
                                      ? "None"
                                        : "-";
                                return;
                            }
                        });
        
                        row.leaderCommissions.forEach((item) => {
                            if (item.user._id === user.auth.userId) {
                                commStatus =
                                    item.leaderReferralCommStatus.fastComm ===
                                    100
                                        ? "FastComm-Full"
                                        : item.leaderReferralCommStatus
                                              .fastComm === 50
                                        ? "FastComm-Half"
                                        : item.leaderReferralCommStatus
                                        .fastComm === 0
                                  ? "None"
                                        : "-";
                                return;
                            }
                        });

                        return (
                            
                            (
                                ((commStatus === "-" && moment(row.bookingDate).isAfter(
                                    "2025-03-16"
                                ) &&
                                    moment(row.createdAt).isBefore(
                                        sevenDaysLater
                                    ) &&
                                    (row.status === "converted" ||
                                        row.status === "pending")))
                            )
                        );
                    })
                );
            },
            
        }
    );

    // const { data: downlinesData } = useQuery(queries.FETCH_DOWNLINES, {
    //     variables: {
    //         limit: paginationState.limit,
    //         page: paginationState.page,
    //         name: filters.name,
    //         referral: filters.referral,
    //     },
    //     fetchPolicy: "network-only",
    //     onCompleted: ({ fetchDownlines: { pagination } }) =>
    //         setPaginationState(pagination),
    // });

    const columns = useMemo(() => {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        if (size.width > 650) {
            return [
                {
                    Header: () => {
                        const isAllSelected = checklist.length === (casesData 
                            ? agentFastCommList.length 
                            : 0);
        
                        return (
                            <div className="flex justify-center items-center">
                                <input
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onClick={(value) => {
                                        if (value) {
                                           
                                            const allRows = agentFastCommList ?  agentFastCommList
                                             : [];
                                            setChecklist(allRows.map(row => (row._id)));
                                            //eslint-disable-next-line
                                            renderList = allRows.map(row => (row._id));
                                        } else {
                                            setChecklist([]);
                                            renderList = [];
                                        }
                                        
                                    }}
                                />
                            </div>
                        );
                    },
                    accessor: "_id",
                    Cell: ({ value, row: { original } }) => {
                        let commStatus = "";
                        original.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                commStatus =
                                    item.fullClaimComm != null
                                        ? "Full Claim"
                                        : item.secondBatchComm != null
                                        ? "Second Batch"
                                        : item.firstBatchComm != null
                                        ? "First Batch"
                                        : item.memberCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.memberCommStatus.fastComm === 50
                                        ? "FastComm-Half"
                                        : item.memberCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                return;
                            }
                        });

                        return (
                            
                            ((commStatus === "-" && original.status === "pending" &&
                                moment(original.bookingDate).isAfter(
                                    "2025-03-16"
                                ) &&
                                moment(original.createdAt).isBefore(
                                    sevenDaysLater
                                ))) && (
                                <div className="flex justify-center items-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            value &&
                                            renderList.some(
                                                (item) => item === value
                                            )
                                        } // Reflect if the ID is in the checklist
                                        onChange={() => {
                                            if (value) {
                                                setChecklist(
                                                    (prevChecklist) => {
                                                        const exists =
                                                            prevChecklist.some(
                                                                (item) =>
                                                                    item ===
                                                                    value
                                                            );

                                                        if (exists) {
                                                            return prevChecklist.filter(
                                                                (item) =>
                                                                    item !==
                                                                    value
                                                            );
                                                        } else {
                                                            return [
                                                                ...prevChecklist,
                                                                value,
                                                            ];
                                                        }
                                                    }
                                                );

                                                if (
                                                    renderList.some(
                                                        (item) => item === value
                                                    )
                                                ) {
                                                    // Remove the item if it's already in the checklist
                                                    //eslint-disable-next-line
                                                    renderList =
                                                        renderList.filter(
                                                            (item) =>
                                                                item !== value
                                                        );
                                                } else {
                                                    // Add the item if it's not in the checklist
                                                    renderList.push(value);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                        );
                    },
                },
                {
                    Header: "Project",
                    accessor: "project",
                    Cell: ({ value }) => {
                        if (value) {
                            return <div> {value.name}</div>;
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "Unit Number",
                    accessor: "unitNumber",
                },
                {
                    Header: "Net Price",
                    accessor: "netPrice",
                    Cell: ({ value }) => (
                        <div>RM {NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Status",
                    accessor: "status",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Booking Date",
                    accessor: "bookingDate",
                },
                {
                    Header: "Converted Date",
                    accessor: "convertDate",
                    Cell: ({ value }) => (
                        <div className="capitalize">
                            {value ? moment(value).format("YYYY-MM-DD") : "-"}
                        </div>
                    ),
                },

                {
                    Header: "Agent Commission Status",
                    id: "commStatus",
                    Cell: ({ row: { original } }) => {
                        let commStatus = "";
                        let memberId = undefined;
                        original.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                commStatus =
                                    item.fullClaimComm != null
                                        ? "Full Claim"
                                        : item.secondBatchComm != null
                                        ? "Second Batch"
                                        : item.firstBatchComm != null
                                        ? "First Batch"
                                        : item.memberCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.memberCommStatus.fastComm === 50
                                        ? "FastComm-Half"
                                         : item.memberCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                memberId = item._id;
                                setTypeCommissionStatus("member");
                                return;
                            }
                        });

                        return (
                            <div className="flex justify-center items-center">
                                <div>{commStatus}</div>
                                {
                                    ((commStatus === "-" && original.status === "pending" &&
                                        moment(original.bookingDate).isAfter(
                                            "2025-03-16"
                                        ) &&
                                        moment(original.createdAt).isBefore(
                                            sevenDaysLater
                                        ))) && (
                                        <div className="cursor-pointer ml-2">
                                            <FiEdit
                                                onClick={() => {
                                                    setTypeReferralCommissionStatus(
                                                        undefined
                                                    );

                                                    original.members.forEach(
                                                        (item) => {
                                                            if (
                                                                item.member
                                                                    ._id ===
                                                                user.auth.userId
                                                            ) {
                                                                setTypeCommissionStatus(
                                                                    "member"
                                                                );
                                                                return;
                                                            }
                                                        }
                                                    );

                                                    setModalData({
                                                        ...modalData,
                                                        nested: original,
                                                        memberId: memberId,
                                                    });

                                                    setChangeAgentCommissionStatusModal(
                                                        true
                                                    );
                                                }}
                                            ></FiEdit>
                                        </div>
                                    )}
                            </div>
                        );
                    },
                },

                {
                    Header: "Leader / Prime Commission Status",
                    id: "referralcommStatus",
                    Cell: ({ row: { original } }) => {
                        let commStatus = "";
                        let memberId = undefined;

                        original.sponsorCommissions.forEach((item) => {
                            if (item.user._id === user.auth.userId) {
                                commStatus =
                                    item.referralCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.referralCommStatus.fastComm ===
                                          50
                                        ? "FastComm-Half"
                                                                                 : item.referralCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                memberId = item._id;
                                return;
                            }
                        });

                        original.leaderCommissions.forEach((item) => {
                            if (item.user._id === user.auth.userId) {
                                commStatus =
                                    item.leaderReferralCommStatus.fastComm ===
                                    100
                                        ? "FastComm-Full"
                                        : item.leaderReferralCommStatus
                                              .fastComm === 50
                                        ? "FastComm-Half"
                                                                                                                         : item.leaderReferralCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                memberId = item._id;

                                return;
                            }
                        });

                        return (
                            <div className="flex justify-center items-center">
                                <div>{commStatus}</div>
                                {
                                    ((commStatus === "-" && moment(original.bookingDate).isAfter(
                                        "2025-03-16"
                                    ) &&
                                        moment(original.createdAt).isBefore(
                                            sevenDaysLater
                                        ) &&
                                        (original.status === "converted" ||
                                            original.status === "pending"))) && (
                                        <div className="cursor-pointer ml-2">
                                            <FiEdit
                                                onClick={() => {
                                                    original.sponsorCommissions.forEach(
                                                        (item) => {
                                                            if (
                                                                item.user
                                                                    ._id ===
                                                                user.auth.userId
                                                            ) {
                                                                setTypeReferralCommissionStatus(
                                                                    "referral"
                                                                );
                                                                return;
                                                            }
                                                        }
                                                    );

                                                    const isUserInSponsorCommissions =
                                                        original.sponsorCommissions.some(
                                                            (item) =>
                                                                item.user
                                                                    ._id ===
                                                                user.auth.userId
                                                        );

                                                    original.leaderCommissions.forEach(
                                                        (item) => {
                                                            if (
                                                                item.user
                                                                    ._id ===
                                                                user.auth.userId
                                                            ) {
                                                                setTypeReferralCommissionStatus(
                                                                    isUserInSponsorCommissions
                                                                        ? "memberreferralleader"
                                                                        : "leaderReferral"
                                                                );
                                                                return;
                                                            }
                                                        }
                                                    );

                                                    setModalData({
                                                        ...modalData,
                                                        nested: original,
                                                        memberId: memberId,
                                                    });

                                                    setChangeCommissionStatusModal(
                                                        true
                                                    );
                                                }}
                                            ></FiEdit>
                                        </div>
                                    )}
                            </div>
                        );
                    },
                },
                {
                    Header: () => {
                        const isAllSelected = uplinechecklist.length === (casesData 
                            ? leaderFastCommList.length 
                            : 0);
        
                        return (
                            <div className="flex justify-center items-center">
                                <input
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onClick={(value) => {
                                        if (value) {
                                           
                                            const allRows = leaderFastCommList ?  leaderFastCommList
                                             : [];
                                             setUplineChecklist(allRows.map(row => (row._id)));
                                               //eslint-disable-next-line
                                            uplineRenderList = allRows.map(row => (row._id));
                                        } else {
                                            setUplineChecklist([]);
                                            uplineRenderList = [];
                                        }
                                        
                                    }}
                                />
                            </div>
                        );
                    },
                    accessor: "uplineFastComm",
                    Cell: ({ row: { original } }) => {
                        const value = original._id
                        let commStatus = "";
                        original.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                commStatus =
                                    item.fullClaimComm != null
                                        ? "Full Claim"
                                        : item.secondBatchComm != null
                                        ? "Second Batch"
                                        : item.firstBatchComm != null
                                        ? "First Batch"
                                        : item.memberCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.memberCommStatus.fastComm === 50
                                        ? "FastComm-Half"
                                           : item.memberCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                return;
                            }
                        });

                        return (
                            
                            ((commStatus === "-" && moment(original.bookingDate).isAfter(
                                "2025-03-16"
                            ) &&
                                moment(original.createdAt).isBefore(
                                    sevenDaysLater
                                ) &&
                                (original.status === "converted" ||
                                    original.status === "pending"))) && (
                                <div className="flex justify-center items-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            value &&
                                            uplineRenderList.some(
                                                (item) => item === value
                                            )
                                        } // Reflect if the ID is in the checklist
                                        onChange={() => {
                                            if (value) {
                                                setUplineChecklist(
                                                    (prevChecklist) => {
                                                        const exists =
                                                            prevChecklist.some(
                                                                (item) =>
                                                                    item ===
                                                                    value
                                                            );

                                                        if (exists) {
                                                            return prevChecklist.filter(
                                                                (item) =>
                                                                    item !==
                                                                    value
                                                            );
                                                        } else {
                                                            return [
                                                                ...prevChecklist,
                                                                value,
                                                            ];
                                                        }
                                                    }
                                                );

                                                if (
                                                    uplineRenderList.some(
                                                        (item) => item === value
                                                    )
                                                ) {
                                                    // Remove the item if it's already in the checklist
                                                    //eslint-disable-next-line
                                                    uplineRenderList =
                                                    uplineRenderList.filter(
                                                            (item) =>
                                                                item !== value
                                                        );
                                                } else {
                                                    // Add the item if it's not in the checklist
                                                    uplineRenderList.push(value);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                        );
                    },
                },
                {
                    Header: "Involved Agent",
                    accessor: "worker",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },

                {
                    Header: "",
                    id: "actions",
                    Cell: ({ row: { original } }) => {
                        return (
                            <div className="flex justify-center items-center cursor-pointer">
                                <div
                                    className="mr-2"
                                    onClick={() => {
                                        setModalState(true);
                                        setModalData({
                                            ...original,
                                        });
                                    }}
                                >
                                    <FiFileText />
                                </div>

                                <div
                                    onClick={() => {
                                        setCommissionState(true);
                                        let totalAgentComm = 0;
                                        let totalLeaderComm = 0;
                                        let leadercommissionPercentage = 0;
                                        let totalSponsorComm = 0;
                                        let primeleadercommissionPercentage = 0;
                                        let commissionPercentage = 0;

                                        original.members.forEach((item) => {
                                            if (
                                                item.member._id ===
                                                user.auth.userId
                                            ) {
                                                const agentComm =
                                                    item.newCommission
                                                        ? (item.newCommission *
                                                              original.netPrice) /
                                                          100 /
                                                          1.08
                                                        : (item.commission *
                                                              original.netPrice) /
                                                          100 /
                                                          1.08;
                                                totalAgentComm =
                                                    totalAgentComm + agentComm;
                                                const commission =
                                                    item.newCommission
                                                        ? item.newCommission
                                                        : item.commission;
                                                commissionPercentage =
                                                    commissionPercentage +
                                                    commission;
                                                return;
                                            }
                                        });

                                        original.leaderCommissions.forEach(
                                            (item, i) => {
                                                if (
                                                    item.user._id ===
                                                    user.auth.userId
                                                ) {
                                                    const leaderComm =
                                                        (item.commission *
                                                            original.netPrice) /
                                                        100 /
                                                        1.08;
                                                    totalLeaderComm =
                                                        totalLeaderComm +
                                                        leaderComm;
                                                    primeleadercommissionPercentage =
                                                        item.newCommission
                                                            ? item.newCommission
                                                            : item.commission;
                                                }
                                            }
                                        );

                                        original.sponsorCommissions.forEach(
                                            (item, i) => {
                                                if (
                                                    item.user._id ===
                                                    user.auth.userId
                                                ) {
                                                    const sponsorComm =
                                                        (item.commission *
                                                            original.netPrice) /
                                                        100 /
                                                        1.08;
                                                    totalSponsorComm =
                                                        totalSponsorComm +
                                                        sponsorComm;
                                                    leadercommissionPercentage =
                                                        item.newCommission
                                                            ? item.newCommission
                                                            : item.commission;
                                                }
                                            }
                                        );
                                        setModalData({
                                            ...original,
                                            agentCommission: totalAgentComm,
                                            leaderCommission: totalSponsorComm,
                                            primeCommission: totalLeaderComm,
                                            commissionPercentage:
                                                commissionPercentage,
                                            leadercommissionPercentage:
                                                leadercommissionPercentage,
                                            primeleadercommissionPercentage:
                                                primeleadercommissionPercentage,
                                        });
                                    }}
                                >
                                    <FiMonitor />
                                </div>
                            </div>
                        );
                    },
                },
            ];
        } else {
            return [
                {
                    Header: () => {
                        const isAllSelected = checklist.length === (casesData 
                            ? agentFastCommList.length 
                            : 0);
        
                        return (
                            <div className="flex justify-center items-center">
                                <input
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onClick={(value) => {
                                        if (value) {
                                           
                                            const allRows = agentFastCommList ?  agentFastCommList
                                             : [];
                                            setChecklist(allRows.map(row => (row._id)));
                                            //eslint-disable-next-line
                                            renderList = allRows.map(row => (row._id));
                                        } else {
                                            setChecklist([]);
                                            renderList = [];
                                        }
                                        
                                    }}
                                />
                            </div>
                        );
                    },
                    accessor: "_id",
                    Cell: ({ value, row: { original } }) => {
                        let commStatus = "";
                        original.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                commStatus =
                                    item.fullClaimComm != null
                                        ? "Full Claim"
                                        : item.secondBatchComm != null
                                        ? "Second Batch"
                                        : item.firstBatchComm != null
                                        ? "First Batch"
                                        : item.memberCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.memberCommStatus.fastComm === 50
                                        ? "FastComm-Half"
                                           : item.memberCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                return;
                            }
                        });

                        return (
                            
                            ((commStatus === "-" && original.status === "pending" &&
                                moment(original.bookingDate).isAfter(
                                    "2025-03-16"
                                ) &&
                                moment(original.createdAt).isBefore(
                                    sevenDaysLater
                                ))) && (
                                <div className="flex justify-center items-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            value &&
                                            renderList.some(
                                                (item) => item === value
                                            )
                                        } // Reflect if the ID is in the checklist
                                        onChange={() => {
                                            if (value) {
                                                setChecklist(
                                                    (prevChecklist) => {
                                                        const exists =
                                                            prevChecklist.some(
                                                                (item) =>
                                                                    item ===
                                                                    value
                                                            );

                                                        if (exists) {
                                                            return prevChecklist.filter(
                                                                (item) =>
                                                                    item !==
                                                                    value
                                                            );
                                                        } else {
                                                            return [
                                                                ...prevChecklist,
                                                                value,
                                                            ];
                                                        }
                                                    }
                                                );

                                                if (
                                                    renderList.some(
                                                        (item) => item === value
                                                    )
                                                ) {
                                                    // Remove the item if it's already in the checklist
                                                    //eslint-disable-next-line
                                                    renderList =
                                                        renderList.filter(
                                                            (item) =>
                                                                item !== value
                                                        );
                                                } else {
                                                    // Add the item if it's not in the checklist
                                                    renderList.push(value);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                        );
                    },
                },
                {
                    Header: "Unit Number",
                    id: "unitNumber",
                    Cell: ({ row: { original } }) => {
                        if (original) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {original.unitNumber}
                                </div>
                            );
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "Booking Date",
                    accessor: "bookingDate",
                },
                {
                    Header: "Agent Commission",
                    id: "commission",
                    Cell: ({ row: { original } }) => {
                        let totalAgentComm = 0;
                        original.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                const agentComm = item.newCommission
                                    ? (item.newCommission * original.netPrice) /
                                      100 /
                                      1.08
                                    : (item.commission * original.netPrice) /
                                      100 /
                                      1.08;
                                totalAgentComm = totalAgentComm + agentComm;
                                return;
                            }
                        });
                        return (
                            <div className="flex justify-center items-center">
                                RM {NumberFormatter.format(totalAgentComm)}
                            </div>
                        );
                    },
                },
                {
                    Header: "Agent Comm",
                    id: "commStatus",
                    Cell: ({ row: { original } }) => {
                        let commStatus = "";
                        let memberId = undefined;
                        original.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                commStatus =
                                    item.fullClaimComm != null
                                        ? "Full Claim"
                                        : item.secondBatchComm != null
                                        ? "Second Batch"
                                        : item.firstBatchComm != null
                                        ? "First Batch"
                                        : item.memberCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.memberCommStatus.fastComm === 50
                                        ? "FastComm-Half"
                                         : item.memberCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                memberId = item._id;
                                setTypeCommissionStatus("member");
                                return;
                            }
                        });

                        return (
                            <div className="flex justify-center items-center">
                                <div>{commStatus}</div>
                                {
                                    ((commStatus === "-" && original.status === "pending" &&
                                        moment(original.bookingDate).isAfter(
                                            "2025-03-16"
                                        ) &&
                                        moment(original.createdAt).isBefore(
                                            sevenDaysLater
                                        ))) && (
                                        <div className="cursor-pointer ml-2">
                                            <FiEdit
                                                onClick={() => {
                                                    setTypeReferralCommissionStatus(
                                                        undefined
                                                    );

                                                    original.members.forEach(
                                                        (item) => {
                                                            if (
                                                                item.member
                                                                    ._id ===
                                                                user.auth.userId
                                                            ) {
                                                                setTypeCommissionStatus(
                                                                    "member"
                                                                );
                                                                return;
                                                            }
                                                        }
                                                    );

                                                    setModalData({
                                                        ...modalData,
                                                        nested: original,
                                                        memberId: memberId,
                                                    });

                                                    setChangeAgentCommissionStatusModal(
                                                        true
                                                    );
                                                }}
                                            ></FiEdit>
                                        </div>
                                    )}
                            </div>
                        );
                    },
                },

                {
                    Header: "Overriding Comm",
                    id: "referralcommStatus",
                    Cell: ({ row: { original } }) => {
                        let commStatus = "";
                        let memberId = undefined;

                        original.sponsorCommissions.forEach((item) => {
                            if (item.user._id === user.auth.userId) {
                                commStatus =
                                    item.referralCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.referralCommStatus.fastComm ===
                                          50
                                        ? "FastComm-Half"
                                        : item.referralCommStatus.fastComm ===
                                        0
                                      ? "None"
                                        : "-";
                                memberId = item._id;
                                return;
                            }
                        });

                        original.leaderCommissions.forEach((item) => {
                            if (item.user._id === user.auth.userId) {
                                commStatus =
                                    item.leaderReferralCommStatus.fastComm ===
                                    100
                                        ? "FastComm-Full"
                                        : item.leaderReferralCommStatus
                                              .fastComm === 50
                                        ? "FastComm-Half"
                                        : item.leaderReferralCommStatus
                                              .fastComm === 0
                                        ? "None"
                                        : "-";
                                memberId = item._id;

                                return;
                            }
                        });

                        return (
                            <div className="flex justify-center items-center">
                                <div>{commStatus}</div>
                                {
                                    ((commStatus === "-" && moment(original.bookingDate).isAfter(
                                        "2025-03-16"
                                    ) &&
                                        moment(original.createdAt).isBefore(
                                            sevenDaysLater
                                        ) &&
                                        (original.status === "converted" ||
                                            original.status === "pending"))) && (
                                        <div className="cursor-pointer ml-2">
                                            <FiEdit
                                                onClick={() => {
                                                    original.sponsorCommissions.forEach(
                                                        (item) => {
                                                            if (
                                                                item.user
                                                                    ._id ===
                                                                user.auth.userId
                                                            ) {
                                                                setTypeReferralCommissionStatus(
                                                                    "referral"
                                                                );
                                                                return;
                                                            }
                                                        }
                                                    );

                                                    const isUserInSponsorCommissions =
                                                        original.sponsorCommissions.some(
                                                            (item) =>
                                                                item.user
                                                                    ._id ===
                                                                user.auth.userId
                                                        );

                                                    original.leaderCommissions.forEach(
                                                        (item) => {
                                                            if (
                                                                item.user
                                                                    ._id ===
                                                                user.auth.userId
                                                            ) {
                                                                setTypeReferralCommissionStatus(
                                                                    isUserInSponsorCommissions
                                                                        ? "memberreferralleader"
                                                                        : "leaderReferral"
                                                                );
                                                                return;
                                                            }
                                                        }
                                                    );

                                                    setModalData({
                                                        ...modalData,
                                                        nested: original,
                                                        memberId: memberId,
                                                    });

                                                    setChangeCommissionStatusModal(
                                                        true
                                                    );
                                                }}
                                            ></FiEdit>
                                        </div>
                                    )}
                            </div>
                        );
                    },
                },
                {
                    Header: () => {
                        const isAllSelected = uplinechecklist.length === (casesData 
                            ? leaderFastCommList.length 
                            : 0);
        
                        return (
                            <div className="flex justify-center items-center">
                                <input
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onClick={(value) => {
                                        if (value) {
                                           
                                            const allRows = leaderFastCommList ?  leaderFastCommList
                                             : [];
                                             setUplineChecklist(allRows.map(row => (row._id)));
                                               //eslint-disable-next-line
                                            uplineRenderList = allRows.map(row => (row._id));
                                        } else {
                                            setUplineChecklist([]);
                                            uplineRenderList = [];
                                        }
                                        
                                    }}
                                />
                            </div>
                        );
                    },
                    accessor: "uplineFastComm",
                    Cell: ({ row: { original } }) => {
                        const value = original._id
                        let commStatus = "";
                        original.members.forEach((item) => {
                            if (item.member._id === user.auth.userId) {
                                commStatus =
                                    item.fullClaimComm != null
                                        ? "Full Claim"
                                        : item.secondBatchComm != null
                                        ? "Second Batch"
                                        : item.firstBatchComm != null
                                        ? "First Batch"
                                        : item.memberCommStatus.fastComm === 100
                                        ? "FastComm-Full"
                                        : item.memberCommStatus.fastComm === 50
                                        ? "FastComm-Half"
                                          : item.memberCommStatus.fastComm === 0
                                        ? "None"
                                        : "-";
                                return;
                            }
                        });

                        return (
                            ((commStatus === "-" && moment(original.bookingDate).isAfter(
                                "2025-03-16"
                            ) &&
                                moment(original.createdAt).isBefore(
                                    sevenDaysLater
                                ) &&
                                (original.status === "converted" ||
                                    original.status === "pending"))) && (
                                <div className="flex justify-center items-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            value &&
                                            uplineRenderList.some(
                                                (item) => item === value
                                            )
                                        } // Reflect if the ID is in the checklist
                                        onChange={() => {
                                            if (value) {
                                                setUplineChecklist(
                                                    (prevChecklist) => {
                                                        const exists =
                                                            prevChecklist.some(
                                                                (item) =>
                                                                    item ===
                                                                    value
                                                            );

                                                        if (exists) {
                                                            return prevChecklist.filter(
                                                                (item) =>
                                                                    item !==
                                                                    value
                                                            );
                                                        } else {
                                                            return [
                                                                ...prevChecklist,
                                                                value,
                                                            ];
                                                        }
                                                    }
                                                );

                                                if (
                                                    uplineRenderList.some(
                                                        (item) => item === value
                                                    )
                                                ) {
                                                    // Remove the item if it's already in the checklist
                                                    //eslint-disable-next-line
                                                    uplineRenderList =
                                                    uplineRenderList.filter(
                                                            (item) =>
                                                                item !== value
                                                        );
                                                } else {
                                                    // Add the item if it's not in the checklist
                                                    uplineRenderList.push(value);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                        );
                    },
                },
            ];
        }
    }, [modalData, size.width, setModalData, user.auth.userId,agentFastCommList]);

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return (
        <>
            {isLoading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div
                    className="min-h-screen bg-cover"
                    style={{
                        backgroundImage:
                            "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp')",
                    }}
                >
                    <div className="py-4 px-6 lg:px-12">
                        <div className="table-header-actions flex flex-wrap justify-between items-start mb-5">
                            <div className="w-full lg:pr-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 col-gap-4 row-gap-4">
                                    <div className="">
                                        <input
                                            className="w-full p-1 lg:p-3 h-full px-4 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs p-2"
                                            type="date"
                                            placeholder="YYYY-MM-DD"
                                            onChange={(e) => {
                                                e.persist();
                                                setIsLoading(true);
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    dateFrom: e.target.value,
                                                }));
                                            }}
                                            value={filters.dateFrom}
                                        />
                                    </div>
                                    <div className="">
                                        <input
                                            className="w-full p-1 lg:p-3 h-full px-4 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs p-2"
                                            type="date"
                                            placeholder="YYYY-MM-DD"
                                            onChange={(e) => {
                                                e.persist();
                                                setIsLoading(true);
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    dateTo: e.target.value,
                                                }));
                                            }}
                                            value={filters.dateTo}
                                        />
                                    </div>
                                    <div className="">
                                        <Select
                                            styles={filterSelectStyles}
                                            isSearchable={false}
                                            options={statusOptions}
                                            value={filters.status}
                                            placeholder="Status"
                                            onChange={(val) => {
                                                setPaginationState((prev) => ({
                                                    ...prev,
                                                    page: 1,
                                                }));
                                                setIsLoading(true);
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    status: val,
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <Select
                                            styles={filterSelectStyles}
                                            options={
                                                projectsData
                                                    ? projectsData.fetchProjectsAgent.data.map(
                                                          (project) => ({
                                                              label: project.name,
                                                              value: project._id,
                                                          })
                                                      )
                                                    : []
                                            }
                                            value={filters.project}
                                            placeholder="Project"
                                            onChange={(val) => {
                                                setPaginationState((prev) => ({
                                                    ...prev,
                                                    page: 1,
                                                }));
                                                setIsLoading(true);
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    project: val,
                                                }));
                                            }}
                                        />
                                    </div>
                                    {/* <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={
                                    downlinesData
                                        ? downlinesData.fetchDownlines
                                              .uplineFilterList
                                        : []
                                }
                                value={filters.downline}
                                placeholder="Downline"
                                onChange={(val) => {
                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: 1,
                                    }));
                                     setIsLoading(true)
                                    setFilters((prev) => ({
                                        ...prev,
                                        downline: val,
                                    }));
                                }}
                            />
                        </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="table-header-actions flex justify-between items-start mt-8">
                            <div className="w-full lg:pr-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 col-gap-4">
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-01_0X6DfeObd.webp"
                                                alt="casestotal"
                                            />
                                            <div className="ml-4 text-white text-sm">
                                                Cases Total:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? casesData
                                                                  .fetchCasesAgent
                                                                  .totals.cases
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-05_TSMY7dRdQ.webp"
                                                alt="spatotal"
                                            />
                                            <div className="ml-4 text-white text-sm">
                                                Spa Price Total:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .spaPrice
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-02_5pwSHlrtR.webp"
                                                alt="nettotal"
                                            />
                                            <div className="ml-4 text-white text-sm">
                                                Net Price Total:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .netPrice
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-04_F6rcRq_oO.webp"
                                                alt="netgdv"
                                            />
                                            <div className="ml-4 text-sm">
                                                Total Net GDV:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalNetGdv
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-06_VoI03VJDv.webp"
                                                alt="groupnetgdv"
                                            />
                                            <div className="ml-4 text-sm">
                                                Total Group Net GDV:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalGroupNetGdv
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-07_aqlBxxAYeL.webp"
                                                alt="commpercentage"
                                            />
                                            <div className="ml-4 text-sm">
                                                Commission Percentage:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? casesData
                                                                  .fetchCasesAgent
                                                                  .totals
                                                                  .totalCommissionPercentage
                                                            : "0"
                                                        : "0"}
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-09_Q6CFk6g30o.webp"
                                                alt="personalcom"
                                            />
                                            <div className="ml-4 text-sm">
                                                Personal Commission:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalCommission
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-10_0CQQTaRXW-1.webp"
                                                alt="leadercomm"
                                            />
                                            <div className="ml-4 text-sm">
                                                Leader Commission:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalLeaderComm
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-11_gQA43N_h7.webp"
                                                alt="primecomm"
                                            />
                                            <div className="ml-4 text-sm">
                                                Prime Commission:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalPrimeLeaderComm
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-13_Q25Buu9Lf.webp"
                                                alt="ptp5comm"
                                            />
                                            <div className="ml-4 text-sm">
                                                PTP 5 Commission:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalPtpReferralComm
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-14_o5S0cl_hW.webp"
                                                alt="ptp2comm"
                                            />
                                            <div className="ml-4 text-sm">
                                                PTP 2 Commission:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalPtp2ReferralComm
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                            <img
                                                className="h-full w-16"
                                                src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-15_fLW1RE2QJm.webp"
                                                alt="ptp1comm"
                                            />
                                            <div className="ml-4 text-sm">
                                                PTP 1 Commission:{" "}
                                                <div className="text-sm lg:text-2xl">
                                                    RM
                                                    {casesData
                                                        ? casesData
                                                              .fetchCasesAgent
                                                              .totals
                                                            ? NumberFormatter.format(
                                                                  casesData
                                                                      .fetchCasesAgent
                                                                      .totals
                                                                      .totalPtp1ReferralComm
                                                              )
                                                            : "0"
                                                        : "0"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button
                                className="font-semibold bg-blue-600 hover:bg-blue-700 text-white py-2 px-3 lg:mr-1 rounded w-full  mr-2 lg:w-1/5 transition duration-300"
                                onClick={() => {
                                    setAgentBulkChangeCommissionStatusModal(
                                        true
                                    );
                                    setTypeCommissionStatus("member");
                                }}
                            >
                                Agent Comm
                            </button>
                            <button className="font-semibold bg-green-600 hover:bg-green-700 text-white py-2 px-3 lg:mr-1 rounded w-full mr-2 lg:w-1/5 transition duration-300"
                             onClick={() => {
                                setBulkChangeCommissionStatusModal(true)
                            }}>
                                Overriding Comm
                            </button>
                        </div>
                        <Table
                            columns={columns}
                            data={
                                casesData ? casesData.fetchCasesAgent.data : []
                            }
                            updatePagination={setPaginationState}
                            paginationState={paginationState}
                            loading={casesLoading}
                            pageCount={
                                casesData
                                    ? casesData.fetchCasesAgent.pagination
                                          .totalPages
                                    : 0
                            }
                            totalCount={
                                casesData
                                    ? casesData.fetchCasesAgent.pagination
                                          .documents
                                    : 0
                            }
                            hiddenFields={
                                user.auth.ranking.name === "agent"
                                    ? ["leaderCommission", "primeCommission"]
                                    : user.auth.ranking.name === "leader"
                                    ? ["primeCommission"]
                                    : undefined
                            }
                        />

                        {modalState && (
                            <Modal reset={resetModalData}>
                                <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                                <div className="container h-full mx-auto flex justify-start">
                                    <div className="panel bg-white p-6 rounded-md w-full">
                                        <div className="flex justify-end">
                                            <FiX
                                                onClick={() => {
                                                    setModalState(false);
                                                }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                        <div className="mb-4 text-3xl font-bold text-center">
                                            Case Summary
                                        </div>

                                        {formFields.map((item, i) => {
                                            if (
                                                item.name === "purchaserName" ||
                                                item.name === "purchaserIc" ||
                                                item.name === "purchaserContact"
                                            ) {
                                                return (
                                                    <div
                                                        className="flex row"
                                                        key={i}
                                                    >
                                                        <div className="lg:w-1/3 w-1/2 text-right flex items-center justify-start mb-2 text-sm text-lynch font-medium">
                                                            {item.label} :
                                                        </div>
                                                        <div className="my-2 p-3 lg:w-2/3 w-1/2 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs capitalize">
                                                            {modalData.purchaser
                                                                ? modalData
                                                                      .purchaser[
                                                                      item.name
                                                                  ]
                                                                : ""}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div
                                                    className="flex row"
                                                    key={item.name}
                                                >
                                                    <div className="lg:w-1/3 w-1/2 text-right flex items-center justify-start mb-2 text-sm text-lynch font-medium">
                                                        {item.label} :
                                                    </div>
                                                    <div className="custom-min-height my-2 p-3 lg:w-2/3 w-1/2 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs capitalize">
                                                        {item.type === "numbers"
                                                            ? `RM ${NumberFormatter.format(
                                                                  modalData[
                                                                      item.name
                                                                  ]
                                                              )}`
                                                            : modalData[
                                                                  item.name
                                                              ]}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className="flex row">
                                            <div className="lg:w-1/3 w-1/2 text-right flex items-center justify-start mb-2 text-sm text-lynch font-medium">
                                                Project :
                                            </div>
                                            <div className="custom-min-height my-2 p-3 lg:w-2/3 w-1/2 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs">
                                                {modalData.project.name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        )}

                        {changeCommissionStatusModal && (
                            <Modal reset={resetCommissionStatusModal}>
                                <div className="container h-full mx-auto flex justify-center items-center">
                                    <div className="panel bg-white p-6 rounded-md">
                                        <div className="mb-4 text-3xl font-bold">
                                            Change Commission Status
                                        </div>
                                        <form
                                            onSubmit={handleSubmitStatusForm(
                                                typeReferralCommissionStatus
                                                    ? handleEditLeaderStatus
                                                    : handleEditStatus
                                            )}
                                            className="lg:w-104"
                                        >
                                            <div>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    Fast Comm Status
                                                </div>
                                                <select
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs
                                        `}
                                                    name="fastComm"
                                                    ref={registerStatusForm({
                                                        required: false,
                                                    })}
                                                    placeholder="Status"
                                                    type="string"
                                                >
                                                    <option value="0">
                                                        None
                                                    </option>
                                                    <option value="100">
                                                        Full Comm
                                                    </option>
                                                </select>
                                            </div>
                                            <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </Modal>
                        )}

                        {agentBulkChangeCommissionStatusModal && (
                            <Modal reset={resetBulkCommissionStatusModal}>
                                <div className="container h-full mx-auto flex justify-center items-center">
                                    <div className="panel bg-white p-6 rounded-md">
                                        <div className="mb-4 text-3xl font-bold">
                                            Bulk Agent Comm
                                        </div>
                                        <form
                                            onSubmit={handleSubmitStatusForm(
                                                handleEditBulkStatus
                                            )}
                                            className="lg:w-104"
                                        >
                                            <div>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    Fast Comm Status
                                                </div>
                                                <select
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs
                                        `}
                                                    name="fastComm"
                                                    ref={registerStatusForm({
                                                        required: false,
                                                    })}
                                                    placeholder="Status"
                                                    type="string"
                                                >
                                                    <option value="50">
                                                        Fast Comm
                                                    </option>
                                                    <option value="100">
                                                        Full Comm
                                                    </option>
                                                </select>
                                            </div>
                                            <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </Modal>
                        )}

                        {bulkChangeCommissionStatusModal && (
                            <Modal reset={resetBulkUplineCommissionStatusModal}>
                                <div className="container h-full mx-auto flex justify-center items-center">
                                    <div className="panel bg-white p-6 rounded-md">
                                        <div className="mb-4 text-3xl font-bold">
                                        Bulk Overriding Comm
                                        </div>
                                        <form
                                            onSubmit={handleSubmitStatusForm(
                                                handleEditBulkUplineStatus
                                            )}
                                            className="lg:w-104"
                                        >
                                            <div>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    Fast Comm Status
                                                </div>
                                                <select
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs
                                        `}
                                                    name="fastComm"
                                                    ref={registerStatusForm({
                                                        required: false,
                                                    })}
                                                    placeholder="Status"
                                                    type="string"
                                                >
                                                    <option value="0">
                                                        None
                                                    </option>
                                                    <option value="100">
                                                        Full Comm
                                                    </option>
                                                </select>
                                            </div>
                                            <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </Modal>
                        )}


                        {changeAgentCommissionStatusModal && (
                            <Modal reset={resetAgentCommissionStatusModal}>
                                <div className="container h-full mx-auto flex justify-center items-center">
                                    <div className="panel bg-white p-6 rounded-md">
                                        <div className="mb-4 text-3xl font-bold">
                                            Change Commission Status
                                        </div>
                                        <form
                                            onSubmit={handleSubmitStatusForm(
                                                typeReferralCommissionStatus
                                                    ? handleEditLeaderStatus
                                                    : handleEditStatus
                                            )}
                                            className="lg:w-104"
                                        >
                                            <div>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    Fast Comm Status
                                                </div>
                                                <select
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs
                                        `}
                                                    name="fastComm"
                                                    ref={registerStatusForm({
                                                        required: false,
                                                    })}
                                                    placeholder="Status"
                                                    type="string"
                                                >
                                                    <option value="50">
                                                        Fast Comm
                                                    </option>
                                                    <option value="100">
                                                        Full Comm
                                                    </option>
                                                </select>
                                            </div>
                                            <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </Modal>
                        )}

                        {commissionState && (
                            <Modal reset={resetCommissionState}>
                                <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                                <div className="container h-full mx-auto flex justify-start">
                                    <div className="panel bg-white p-6 rounded-md w-full">
                                        <div className="flex justify-end">
                                            <FiX
                                                onClick={() => {
                                                    setCommissionState(false);
                                                }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                        <div className="mb-4 text-3xl font-bold text-center">
                                            Commission Details
                                        </div>

                                        {commissionFields.map((item, i) => {
                                            if (
                                                item.name === "purchaserName" ||
                                                item.name === "purchaserIc" ||
                                                item.name === "purchaserContact"
                                            ) {
                                                return (
                                                    <div
                                                        className="flex row"
                                                        key={i}
                                                    >
                                                        <div className="lg:w-1/3 w-1/2 text-right flex items-center justify-start mb-2 text-sm text-lynch font-medium">
                                                            {item.label} :
                                                        </div>
                                                        <div className="my-2 p-3 lg:w-2/3 w-1/2 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs capitalize">
                                                            {modalData.purchaser
                                                                ? modalData
                                                                      .purchaser[
                                                                      item.name
                                                                  ]
                                                                : ""}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div
                                                    className="flex row"
                                                    key={item.name}
                                                >
                                                    <div className="lg:w-1/3 w-1/2 text-right flex items-center justify-start mb-2 text-sm text-lynch font-medium">
                                                        {item.label} :
                                                    </div>
                                                    <div className="custom-min-height my-2 p-3 lg:w-2/3 w-1/2 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs capitalize">
                                                        {item.type ===
                                                            "numbers" &&
                                                        item.label !==
                                                            "Commission Percentage" &&
                                                        item.label !==
                                                            "Leader Commission Percentage" &&
                                                        item.label !==
                                                            "Prime Commission Percentage"
                                                            ? `RM ${NumberFormatter.format(
                                                                  modalData[
                                                                      item.name
                                                                  ]
                                                              )}`
                                                            : `${
                                                                  modalData[
                                                                      item.name
                                                                  ]
                                                              } %`}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default withUser(Report);
