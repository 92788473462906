import React from "react";
import { Page, Document } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import "../override.css";

/* eslint-disable */
export default ({
  data: {
    name,
    orderNo,
    date,
    method,
    code,
    address,
    address2,
    address3,
    address4,
    address5,
    amount,
    amount2,
    amount3,
    amount4,
    amount5,
    batch,
  },
}) => {
  const commAmount = (amount * 40) / 100;
  const commAmount2 = (amount2 * 40) / 100;
  const commAmount3 = (amount3 * 40) / 100;
  const commAmount4 = (amount4 * 40) / 100;
  const commAmount5 = (amount5 * 40) / 100;
  const bonusAmount = (amount * 60) / 100;
  const bonusAmount2 = (amount2 * 60) / 100;
  const bonusAmount3 = (amount3 * 60) / 100;
  const bonusAmount4 = (amount4 * 60) / 100;
  const bonusAmount5 = (amount5 * 60) / 100;
  const totalCommAmount =
    commAmount + commAmount2 + commAmount3 + commAmount4 + commAmount5;
  const totalBonusAmount =
    bonusAmount + bonusAmount2 + bonusAmount3 + bonusAmount4 + bonusAmount5;

  const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Document>
      <Page size="A4">
        <Container>
          <MainContainer>
            <HeaderContainer>
              <Logo src={"https://ik.imagekit.io/dnddecpho/dndream/dndlogocolor_cLZO36o6H-scaled_aZ4LTb2xf.webp?updatedAt=1741944231230"} />
              <HeaderTitle>D & Dream Properties Sdn Bhd</HeaderTitle>
              <HeaderSubTitle>(Company No: 1250810-W)</HeaderSubTitle>
              <HeaderAddress>
                G-22, Boulevard 28, Jalan Pandan Perdana 3/10,
              </HeaderAddress>
              <HeaderAddress>
                Pandan Perdana, 55300 Kuala Lumpur
              </HeaderAddress>
              <HeaderAddress>Office Hotline: +603-9202 5937</HeaderAddress>
            </HeaderContainer>
            <Title>Payment Voucher</Title>
            <DetailsContainer>
              <DetailsColOne>
                <Detail>
                  <DetailTitle>Code: </DetailTitle>
                  <DetailValue>{code}</DetailValue>
                </Detail>
                <Detail>
                  <DetailTitle>Name: </DetailTitle>
                  <DetailValue>{name}</DetailValue>
                </Detail>
                <Detail>
                  <DetailTitle>Payee: </DetailTitle>
                  <DetailValue>{name}</DetailValue>
                </Detail>
                <Detail>
                  <DetailTitle>Method: </DetailTitle>
                  <DetailValue>{method}</DetailValue>
                </Detail>
                <Detail>
                  <DetailTitle>Order No: </DetailTitle>
                  <DetailValue>{orderNo}</DetailValue>
                </Detail>
              </DetailsColOne>
              <DetailsColTwo>
                <Detail>
                  <DetailTitle>Date: </DetailTitle>
                  <DetailValue>{date}</DetailValue>
                </Detail>
                <Detail>
                  <DetailTitle>Batch: </DetailTitle>
                  <DetailValue>{batch}</DetailValue>
                </Detail>
              </DetailsColTwo>
            </DetailsContainer>
            <CommissionContainer>
              <GridContainer>
                <GridSummary>
                  <GridItemTitle>No</GridItemTitle>
                  <GridItemValue>1</GridItemValue>
                  <GridItemValue>2</GridItemValue>
                  <Line />
                  <GridHidden>
                    <GridItemValue>Hidden</GridItemValue>
                  </GridHidden>
                  <Line />
                </GridSummary>
                <GridSummary>
                  <GridItemTitle>Description</GridItemTitle>
                  <GridItemValue>Agents - Negotiator Comm</GridItemValue>
                  <GridItemValue>Agents - Negotiator Bonus</GridItemValue>
                  <Line />
                  <GridHidden>
                    <GridItemValue>Hidden</GridItemValue>
                  </GridHidden>
                  <Line />
                </GridSummary>
                <GridSummary>
                  <GridItemTitle>Total Amount (RM)</GridItemTitle>
                  <GridItemValue>
                    {NumberFormatter.format(totalCommAmount)}
                  </GridItemValue>
                  <GridItemValue>
                    {NumberFormatter.format(totalBonusAmount)}
                  </GridItemValue>
                  <Line />
                  <GridItemValue>
                    {NumberFormatter.format(totalCommAmount + totalBonusAmount)}
                  </GridItemValue>
                  <Line />
                </GridSummary>
              </GridContainer>
            </CommissionContainer>
            <CommissionContainer>
              <CommissionTitle>Commissions Detail</CommissionTitle>
              <CommissionTitle>Agent - Negotiator Comm</CommissionTitle>
              <GridContainer>
                <GridItem>
                  <GridItemTitle>Code</GridItemTitle>
                  <GridItemValue>{code}</GridItemValue>
                  <GridItemValue> {amount2 == 0 ? "" : code}</GridItemValue>
                  <GridItemValue> {amount3 == 0 ? "" : code}</GridItemValue>
                  <GridItemValue> {amount4 == 0 ? "" : code}</GridItemValue>
                  <GridItemValue> {amount5 == 0 ? "" : code}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>Case From</GridItemTitle>
                  <GridItemValue>{name}</GridItemValue>
                  <GridItemValue> {amount2 == 0 ? "" : name}</GridItemValue>
                  <GridItemValue> {amount3 == 0 ? "" : name}</GridItemValue>
                  <GridItemValue> {amount4 == 0 ? "" : name}</GridItemValue>
                  <GridItemValue> {amount5 == 0 ? "" : name}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>Project</GridItemTitle>
                  <GridItemValue>{address}</GridItemValue>
                  <GridItemValue>{address2}</GridItemValue>
                  <GridItemValue>{address3}</GridItemValue>
                  <GridItemValue>{address4}</GridItemValue>
                  <GridItemValue>{address5}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>PV</GridItemTitle>
                  <GridItemValue>
                    {NumberFormatter.format(amount)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount2 == 0 ? "" : NumberFormatter.format(amount2)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount3 == 0 ? "" : NumberFormatter.format(amount3)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount4 == 0 ? "" : NumberFormatter.format(amount4)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount5 == 0 ? "" : NumberFormatter.format(amount5)}
                  </GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>%</GridItemTitle>
                  <GridItemValue>40</GridItemValue>
                  <GridItemValue>{amount2 == 0 ? "" : 40}</GridItemValue>
                  <GridItemValue>{amount3 == 0 ? "" : 40}</GridItemValue>
                  <GridItemValue>{amount4 == 0 ? "" : 40}</GridItemValue>
                  <GridItemValue>{amount5 == 0 ? "" : 40}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>Amount (RM)</GridItemTitle>
                  <GridItemValue>
                    {NumberFormatter.format(commAmount)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount2 == 0 ? "" : NumberFormatter.format(commAmount2)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount3 == 0 ? "" : NumberFormatter.format(commAmount3)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount4 == 0 ? "" : NumberFormatter.format(commAmount4)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount5 == 0 ? "" : NumberFormatter.format(commAmount5)}
                  </GridItemValue>
                  <Line />
                  <GridItemValue>
                    {NumberFormatter.format(totalCommAmount)}
                  </GridItemValue>
                  <Line />
                </GridItem>
              </GridContainer>
            </CommissionContainer>
            <CommissionContainer>
              <CommissionTitle>Commissions Detail</CommissionTitle>
              <CommissionTitle>Agent - Negotiator Bonus</CommissionTitle>
              <GridContainer>
                <GridItem>
                  <GridItemTitle>Code</GridItemTitle>
                  <GridItemValue>{code}</GridItemValue>
                  <GridItemValue> {amount2 == 0 ? "" : code}</GridItemValue>
                  <GridItemValue> {amount3 == 0 ? "" : code}</GridItemValue>
                  <GridItemValue> {amount4 == 0 ? "" : code}</GridItemValue>
                  <GridItemValue> {amount5 == 0 ? "" : code}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>Case From</GridItemTitle>
                  <GridItemValue>{name}</GridItemValue>
                  <GridItemValue> {amount2 == 0 ? "" : name}</GridItemValue>
                  <GridItemValue> {amount3 == 0 ? "" : name}</GridItemValue>
                  <GridItemValue> {amount4 == 0 ? "" : name}</GridItemValue>
                  <GridItemValue> {amount5 == 0 ? "" : name}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>Project</GridItemTitle>
                  <GridItemValue>{address}</GridItemValue>
                  <GridItemValue>{address2}</GridItemValue>
                  <GridItemValue>{address3}</GridItemValue>
                  <GridItemValue>{address4}</GridItemValue>
                  <GridItemValue>{address5}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>PV</GridItemTitle>
                  <GridItemValue>
                    {NumberFormatter.format(amount)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount2 == 0 ? "" : NumberFormatter.format(amount2)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount3 == 0 ? "" : NumberFormatter.format(amount3)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount4 == 0 ? "" : NumberFormatter.format(amount4)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount5 == 0 ? "" : NumberFormatter.format(amount5)}
                  </GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>%</GridItemTitle>
                  <GridItemValue>60</GridItemValue>
                  <GridItemValue>{amount2 == 0 ? "" : 60}</GridItemValue>
                  <GridItemValue>{amount3 == 0 ? "" : 60}</GridItemValue>
                  <GridItemValue>{amount4 == 0 ? "" : 60}</GridItemValue>
                  <GridItemValue>{amount5 == 0 ? "" : 60}</GridItemValue>
                </GridItem>
                <GridItem>
                  <GridItemTitle>Amount (RM)</GridItemTitle>
                  <GridItemValue>
                    {NumberFormatter.format(bonusAmount)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount2 == 0 ? "" : NumberFormatter.format(bonusAmount2)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount3 == 0 ? "" : NumberFormatter.format(bonusAmount3)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount4 == 0 ? "" : NumberFormatter.format(bonusAmount4)}
                  </GridItemValue>
                  <GridItemValue>
                    {amount5 == 0 ? "" : NumberFormatter.format(bonusAmount5)}
                  </GridItemValue>
                  <Line />
                  <GridItemValue>
                    {NumberFormatter.format(totalBonusAmount)}
                  </GridItemValue>
                  <Line />
                </GridItem>
              </GridContainer>
            </CommissionContainer>
          </MainContainer>
          <SignaturesContainer>
            <SignatureContainer>
              <SignatureTitle>For D & Dream Properties Sdn Bhd</SignatureTitle>
              <SignatureLine />
              <SignaturePerson>Account Department</SignaturePerson>
            </SignatureContainer>
            <SignatureContainer>
              <SignatureLine />
              <SignaturePerson>Received By</SignaturePerson>
            </SignatureContainer>
          </SignaturesContainer>
        </Container>
      </Page>
    </Document>
  );
};

const Container = styled.View`
  width: 100%;
  height: 100%;
  padding-vertical: 40px;
  padding-horizontal: 40px;
`;
const MainContainer = styled.View`
  flex: 1;
  width: 100%;
  align-items: center;
`;
const HeaderContainer = styled.View`
  width: 100%;
  align-items: center;
  position: relative;
`;
const HeaderTitle = styled.Text`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 900;
  margin: 10px;
`;
const HeaderSubTitle = styled.Text`
  font-size: 12px;
  font-weight: 800;
  margin-bottom: 10px;
`;
const HeaderAddress = styled.Text`
  font-size: 10px;
`;
const Title = styled.Text`
  font-size: 20px;
  margin-vertical: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const DetailsContainer = styled.View`
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const DetailsColOne = styled.View`
  flex-basis: 66.66666666%;
`;
const DetailsColTwo = styled.View`
  flex-basis: 33.33333333%;
`;
const Detail = styled.View`
  flex-direction: row;
`;
const DetailTitle = styled.Text`
  font-size: 12px;
`;
const DetailValue = styled.Text`
  font-size: 12px;
  text-transform: capitalize;
`;
const CommissionContainer = styled.View`
  margin-top: 20px;
`;
const CommissionTitle = styled.Text`
  font-size: 11px;
  margin-top: 2px;
`;
const GridContainer = styled.View`
  flex-direction: row;
`;
const GridItem = styled.View`
  flex-basis: 16.6666666666%;
  text-align: center;
`;
const GridSummary = styled.View`
  flex-basis: 33.333333%;
  text-align: center;
`;
const GridItemTitle = styled.Text`
  font-size: 11px;
  font-weight: 800;
  margin-top: 10px;
  margin: 3px;
`;
const GridItemValue = styled.Text`
  font-size: 10px;
  margin: 2px;
  text-transform: capitalize;
`;
const SignaturesContainer = styled.View`
  flex-direction: row;
  width: 100%;
`;
const SignatureContainer = styled.View`
  flex-basis: 50%;
  justify-content: flex-end;
`;
const SignatureTitle = styled.Text`
  font-size: 12px;
`;
const SignatureLine = styled.View`
  width: 150px;
  border-bottom-width: 1px;
  border-color: #000;
  margin-top: 50px;
`;
const SignaturePerson = styled.Text`
  font-size: 12px;
`;
const Line = styled.View`
  border-bottom-width: 1px;
  border-color: #000;
`;
const GridHidden = styled.View`
  color: white;
`;
const Logo = styled.Image`
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
`;
