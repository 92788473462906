import React, { useState, useMemo } from "react";
import queries from "../helpers/queries";
import { useQuery } from "@apollo/react-hooks";
import usePagination from "../Hooks/usePagination";
import { useForm, Controller } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { FaBath, FaBed, FaRegBuilding, FaStreetView } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";

const Projects = withRouter(({ history }) => {
    const { handleSubmit, errors, control, reset } = useForm({
        defaultValues: {
            state: null,
            area: null,
        },
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const updateFilters = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            area: values.area ? values.area.value : "",
            state: values.state ? values.state.value : "",
        }));
        setPaginationState((prev) => ({ ...prev, page: 1 }));
    };

    const [projects, setProjects] = useState(false);
    const [areas, setAreas] = useState([]);
    const [states, setStates] = useState([]);

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });

    const [filters, setFilters] = useState({
        area: null,
        state: null,
    });

    const { pagination } = usePagination({
        numberOfButtons: 2,
        currentPage: paginationState ? paginationState.page : 0,
        totalPages: paginationState ? paginationState.totalPages : 0,
    });

    useQuery(queries.FETCH_PROJECTS, {
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            area: filters.area ? filters.area : "",
            state: filters.state ? filters.state : "",
            project: filters.project ? filters.project.value : "",
        },
        fetchPolicy: "network-only",
        onCompleted: ({
            fetchProjectsAgent: { pagination, ...fetchProjectsAgent },
        }) => {
            setPaginationState(pagination);
            setProjects(fetchProjectsAgent.data);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    useQuery(queries.FETCH_ALL_AREAS, {
        fetchPolicy: "network-only",
        onCompleted: ({ fetchAllAreas: _id, name }) => {
            setAreas(_id, name);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    useQuery(queries.FETCH_ALL_STATES, {
        fetchPolicy: "network-only",
        onCompleted: ({ fetchStates: _id, name }) => {
            setStates(_id, name);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const { data: projectsAllData } = useQuery(queries.FETCH_PROJECTS, {
        fetchPolicy: "network-only",
        variables: {
            limit: 9999,
            page: 1,
            state: "all",
        },
        // onCompleted: ({ fetchProjects: { data } }) => setProjects(data),
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    return (
        <div className="flex h-full bg-gray-100">
            <div
                className="p-8 w-full relative"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp')",
                }}
            >
                <form
                    className="grid grid-cols-1 lg:grid-cols-3 mb-6 col-gap-4 row-gap-4"
                    onSubmit={handleSubmit(updateFilters)}
                >
                    <div className="">
                        <Select
                            styles={filterSelectStyles}
                            placeholder="Project"
                            isSearchable={true}
                            options={
                                projectsAllData
                                    ? projectsAllData.fetchProjectsAgent.data.map(
                                          (project) => ({
                                              label: project.name,
                                              value: project._id,
                                          })
                                      )
                                    : []
                            }
                            value={filters.project}
                            onChange={(val) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    project: val,
                                }))
                            }
                        />
                    </div>
                    <div className="">
                        <Controller
                            as={
                                <Select
                                    styles={filterSelectStyles}
                                    isSearchable={true}
                                />
                            }
                            name="area"
                            placeholder="Area"
                            control={control}
                            options={areas.map((area) => ({
                                label: area.name,
                                value: area._id,
                            }))}
                            value={filters.area}
                        />
                    </div>
                    <div className="">
                        <Controller
                            as={
                                <Select
                                    styles={filterSelectStyles}
                                    isSearchable={true}
                                />
                            }
                            placeholder="State"
                            name="state"
                            control={control}
                            options={states.map((state) => ({
                                label: state.name,
                                value: state._id,
                            }))}
                            value={filters.state}
                        />
                    </div>
                    <div></div>
                    <div></div>
                    <div className="flex flex-row justify-start items-center lg:justify-end text-center">
                        <button className="font-semibold bg-blue-600 hover:bg-blue-700 text-white py-2 px-3 lg:mr-1 rounded w-full w-1/2 mr-2 lg:w-1/3 transition duration-300">
                            Search
                        </button>
                        <div
                            className="font-semibold bg-gray-600 hover:bg-gray-700 text-white py-2 px-3 lg:mr-1 rounded w-full w-1/2 lg:mr-2 lg:w-1/3 cursor-pointer transition duration-300"
                            onClick={() => {
                                setFilters({
                                    name: null,
                                    area: null,
                                });
                                reset();
                            }}
                        >
                            Reset
                        </div>
                    </div>
                </form>
                <div className="lg:p-6 lg:px-24 p-2 ">
                    <div className="grid grid-cols-1 gap-6 text-white">
                        {projects
                            ? projects.map((item, i) => {
                                  return (
                                      <div
                                          className="block border border-gray-200 rounded-lg shadow-lg overflow-hidden"
                                          key={i}
                                      >
                                          <div
                                              className="grid lg:grid-cols-2"
                                              key={i}
                                          >
                                              <div className="relative">
                                                  <img
                                                      className="w-full lg:h-full max-h-base object-cover col-span-4 h-img"
                                                      src={item.image}
                                                      alt={""}
                                                  />
                                              </div>

                                              <div className="flex justify-center border-solid border border-gray-200 flex-col col-span-1 bg-white">
                                                  <div className="w-full ">
                                                      <div className="bg-gray-50 sm:min-h-75vh lg:min-h-45vh justify-center flex flex-col px-8 py-4 lg:p-8 lg:max-h-base">
                                                          <div className="flex flex-col lg:flex-col">
                                                          <div className="flex items-center lg:flex-row">
  {/* Adhoc Container */}
  <div
    className={`${
      item.type === "ongoing" ? "hidden" : ""
    } bg-blue-600 status text-white border border-gray-300 rounded-lg flex justify-center items-center mr-2 mb-2 px-3 py-1 hover:bg-blue-700 transition duration-300 shadow-sm`}
  >
    <span className="capitalize">{item.type}</span>
  </div>

  {/* Active Container */}
  <div
    className={`${
      item.type !== "ongoing" ? "hidden" : ""
    } bg-green-600 status text-white border border-gray-300 rounded-lg flex justify-center items-center mr-2 mb-2 px-3 py-1 hover:bg-green-700 transition duration-300 shadow-sm`}
  >
    <span className="capitalize">{item.status}</span>
  </div>
</div>
                                                              <div className="my-1 text-black text-xl capitalize">
                                                                  <strong>
                                                                      {
                                                                          item.name
                                                                      }
                                                                  </strong>{" "}
                                                              </div>
                                                              <div className="my-1 text-gray-700 text-sm capitalize">
                                                                  <strong>
                                                                      Area:
                                                                  </strong>{" "}
                                                                  {
                                                                      item.area
                                                                          .name
                                                                  }
                                                              </div>
                                                              <div className="my-1 text-gray-700 text-sm capitalize">
                                                                  <strong>
                                                                      State:
                                                                  </strong>{" "}
                                                                  {
                                                                      item.state
                                                                          .name
                                                                  }
                                                              </div>
                                                              <div className="my-1 text-gray-700 text-sm capitalize">
                                                                  <strong>
                                                                      SD:
                                                                  </strong>{" "}
                                                                  {_.capitalize(
                                                                      item
                                                                          .salesDirector
                                                                          .name
                                                                  )}
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="grid grid-cols-1 lg:grid-cols-2">
                                                              <div className="my-1 mb-4 text-gray-700 text-sm capitalize">
                                                                  <div>
                                                                      {" "}
                                                                      <strong>
                                                                          Developer:
                                                                      </strong>
                                                                  </div>
                                                                  <div>
                                                                      {_.capitalize(
                                                                          item.developer
                                                                      )}
                                                                  </div>
                                                              </div>
                                                              <div className="my-1 text-sm text-gray-700">
                                                                  <div>
                                                                      {" "}
                                                                      <strong>
                                                                          Completion
                                                                          :
                                                                      </strong>
                                                                  </div>
                                                                  <div>
                                                                      {
                                                                          item.completionDate
                                                                      }
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <hr />
                                                          <div className="flex flex-col lg:flex-col">
                                                              <div className="flex flex-col lg:flex-col">
                                                                  <div className="flex items-center my-1 text-gray-700 text-sm capitalize">
                                                                  <FaBed className="mr-2" />
                                                                  <span>
                                                                      {
                                                                          item.minBedRoom
                                                                      }{" "}
                                                                      -{" "}
                                                                      {
                                                                          item.maxBedRoom
                                                                      } Bed
                                                                  </span>
                                                                  </div>
                                                              </div>

                                                              <div className="flex flex-col lg:flex-col">
                                                                  <div className="flex items-center my-1 text-gray-700 text-sm capitalize">
                                                                  <FaBath className="mr-2" />
                                                                  <span>
                                                                      {
                                                                          item.minBathRoom
                                                                      }{" "}
                                                                      -{" "}
                                                                      {
                                                                          item.maxBathRoom
                                                                      } Bath
                                                                  </span>
                                                                  </div>
                                                              </div>

                                                              <div className="flex flex-col lg:flex-col">
                                                                  <div className="flex items-center my-1 text-gray-700 text-sm capitalize">
                                                                      <FaStreetView className="mr-2" />
                                                                      {
                                                                          item.minSqft
                                                                      }{" "}
                                                                      -{" "}
                                                                      {
                                                                          item.maxSqft
                                                                      }{" "}
                                                                      sqft
                                                                  </div>
                                                              </div>

                                                              <div className="flex flex-col lg:flex-col">
                                                                  <div className="flex items-center my-1 text-gray-700 text-sm capitalize">
                                                                  <FaRegBuilding className="mr-2" />
                                                                  {item.unit} units
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <hr />

                                                          <div className="lg:mt-6 text-white flex flex-col lg:flex-row justify-between">
                                                              <div className="flex justify-start items-left lg:flex-row lg:items-center">
                                                              <div
                                                                          onClick={() =>
                                                                              history.push(
                                                                                  `/projects/${item._id}`
                                                                              )
                                                                          }
                                                                          className={`cursor-pointer font-semibold bg-black hover:bg-orange-special hover:text-black hover:border-black widerstatus fontorange border borderorange rounded-lg flex justify-center items-center mt-4 lg:my-2 lg:ml-2 lg:p-6 lg:p-4 lg:my-0 w-25 lg:w-32 lg:text-sm transition duration-300`}
                                                                      >
                                                                          Project Info
                                                                      </div>
                                                                  <div
                                                                      onClick={() =>
                                                                          history.push(
                                                                              "/cases",
                                                                              {
                                                                                  project:
                                                                                      {
                                                                                          label: item.name,
                                                                                          value: item._id,
                                                                                      },
                                                                              }
                                                                          )
                                                                      }
                                                                      className={`cursor-pointer font-semibold bg-black hover:bg-orange-special hover:text-black hover:border-black widerstatus fontorange border borderorange rounded-lg flex justify-center items-center mt-4 lg:my-2 lg:ml-2 lg:p-6 lg:p-4 lg:my-0 w-25 lg:w-32 lg:text-sm transition duration-300`}
                                                                  >
                                                                      <span className="capitalize">
                                                                          Cases
                                                                      </span>
                                                                  </div>
                                                                  {item.saleskit && (
                                                                      <a
                                                                          href={`${item.saleskit}`}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          className={`cursor-pointer font-semibold bg-black hover:bg-orange-special hover:text-black hover:border-black widerstatus fontorange border borderorange rounded-lg flex justify-center items-center mt-4 lg:my-2 lg:ml-2 lg:p-6 lg:p-4 lg:my-0 w-25 lg:w-32 lg:text-sm transition duration-300`}
                                                                      >
                                                                          <span className="capitalize">
                                                                              Saleskit
                                                                          </span>
                                                                      </a>
                                                                  )}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
                </div>
                <div className="p-4 bg-gray-800 border-t font-semibold text-white text-xs">
                    <ul className="flex justify-end pr-5">
                        <li
                            className="p-2 cursor-pointer hover:text-blue-600 transition duration-300"
                            onClick={() => {
                                if (paginationState.page !== 1) {
                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: prev.page - 1,
                                    }));
                                }
                            }}
                        >
                            Previous
                        </li>
                        {paginationState.page
                            ? pagination.map((page, i) => (
                                  <li
                                      className={`p-2 px-4 cursor-pointer ${
                                          page === paginationState.page
                                              ? "text-blue-600"
                                              : "text-white"
                                      } hover:text-blue-600 transition duration-300`}
                                      key={i}
                                      onClick={() =>
                                          setPaginationState((prev) => ({
                                              ...prev,
                                              page: page,
                                          }))
                                      }
                                  >
                                      {page}
                                  </li>
                              ))
                            : ""}
                        <li
                            className="p-2 cursor-pointer hover:text-blue-600 transition duration-300"
                            onClick={() => {
                                if (
                                    paginationState.page !==
                                    paginationState.totalPages
                                ) {
                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: prev.page + 1,
                                    }));
                                }
                            }}
                        >
                            Next
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
});

export default Projects;