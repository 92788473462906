import React from "react";
import { Link } from "react-router-dom";
import {
    FcPhoneAndroid,
    FcServices,
    FcVip,
    FcCurrencyExchange,
    FcCopyright,
    FcLibrary,
    FcGlobe,
    FcSalesPerformance,
    FcCapacitor,
    FcSportsMode,
} from "react-icons/fc";
import { withUser } from "../Contexts/user";
import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import queries from "../helpers/queries";

const Dashboard = ({ user }) => {
    const [showPopup, setShowPopup] = useState(true); // Set initial state to true to show the popup
    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const [activeIndex, setActiveIndex] = useState(0);

    const { data: campaignsData } = useQuery(queries.FETCH_CAMPAIGNS_AGENT, {
        fetchPolicy: "network-only",
    });

    const filteredCampaigns = campaignsData ? campaignsData.fetchCampaignsAgent.filter(campaign => campaign.type === "trip") : [];

    const handleNext = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === filteredCampaigns.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleBack = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 0 ? filteredCampaigns.length - 1 : prevIndex - 1
        );
    };

    const getImageSrc = () => {
        if (!user || !user.auth || !user.auth.state) {
            return "";
        }
    
        const stateName = user.auth.state.name;
    
        switch (stateName) {
            case "Kuala Lumpur":
                return "https://venti-fans.com/wp-content/uploads/2025/03/DD_WebsiteExtract_Locations3_f6fNRswza.webp";
            case "Johor Bahru":
                return "https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteExtract_Locations5_gWoVQSzaN.png?updatedAt=1739960193994";
            case "Ipoh":
                return "https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteExtract_Locations2_HdAY6CXxg.png?updatedAt=1739960194226";
                case "Penang":
                    return "https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteExtract_Locations_R_1SU2_jw.png?updatedAt=1739960193997";
            default:
                return "https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteExtract_Locations4_noV3A8ITC.png?updatedAt=1739960194479";
        }
    };

    return (
        <div className="lg:flex h-full tnc-background bg-black">
            <div className="greyOpaqueBackground flex flex-wrap rounded-md w-full overflow-hidden">
                {showPopup && (
                    <div
                        onClick={() => setShowPopup(false)}
                        className="fixed inset-0 p-8 flex items-center justify-center bg-black bg-opacity-50"
                    >
                        <div
                            className="rounded-lg w-full lg:w-1/4 lg:h-3/4 sm:h-3/4 sm:w-1/3 relative"
                            onClick={(e) => e.stopPropagation()} // corrected onclick to onClick
                        >
                            <img
                                src={filteredCampaigns[activeIndex] ? filteredCampaigns[activeIndex].url : ""}
                                className="w-full h-full object-contain"
                                alt="active" // corrected attribute to alt
                            />

                            <button
                                onClick={handleBack}
                                className="absolute top-0 left-0 m-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 px-4 py-2"
                            >
                                &lt; {/* Displaying < using HTML entity */}
                            </button>

                            <button
                                onClick={handleNext}
                                className="absolute top-0 right-0 m-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 px-4 py-2"
                            >
                                &gt; {/* Displaying > using HTML entity */}
                            </button>

                            <div className="flex justify-center items-center absolute inset-0 mt-72">
                                <Link to="/campaigns">
                                    <button className="px-8 border-black border-2 py-4 mb-4 bg-black text-white cursor-pointer text-lg font-bold hover:bg-specialpink hover:text-black">
                                        VIEW
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="w-full lg:w-full p-8">
                    <div className="text-white text-3xl capitalize text-left lg:text-left">
                        {user ? user.auth.nickname : ""}
                    </div>
                    <div className="text-xl capitalize text-left lg:text-left">
                        {user ? user.auth.name : ""}
                    </div>
                    <div className="flex items-center my-2 capitalize italic justify-start lg:justify-start">
                        <FcVip className="mr-2" />
                        {user.auth.progression
                            ? user.auth.director === "SD"
                                ? "Sales Director"
                                : user.auth.director === "PD"
                                ? "Prime Director"
                                : user.auth.director === "coordinator"
                                ? "Coordinator"
                                : user.auth.progression
                            : ""}
                    </div>
                    <div className="grid grid-cols-1 col-gap-4 row-gap-2 lg:flex text-black ">
                        <Link to="/changepassword">
                            <button className="font-semibold bg-specialgreen hover:bg-icongreen hover:text-white py-2 px-3 rounded w-full">
                                Change Password
                            </button>
                        </Link>
                        <a
                            className={`${
                                user.auth.newcp58 !== null && user.auth.newcp58 !== ""
                                    ? ""
                                    : "hidden"
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={user.auth.newcp58 ? user.auth.newcp58 : user.auth.cp58 ? user.auth.cp58 : ""}
                        >
                            <button className="font-semibold bg-specialblue hover:bg-iconblue hover:text-white py-2 px-3 rounded w-full">
                                Download CP58
                            </button>
                        </a>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 row-gap-4 my-8">
                        <div className="border-b border-orange-400">
                            <div className="py-4">
                                <div className="flex items-center ">
                                    <FcCapacitor className="mr-2" />
                                    <div className="font-bold">IC Number</div>
                                </div>
                                <div className="">
                                    {user ? user.auth.ic : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-orange-400">
                            <div className="py-4">
                                <div className="flex items-center ">
                                    <FcPhoneAndroid className="mr-2" />
                                    <div className="font-bold">
                                        Phone Number
                                    </div>
                                </div>
                                <div className="">
                                    {user ? user.auth.phone : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-orange-400">
                            <div className="py-4">
                                <div className="flex items-center ">
                                    <FcGlobe className="mr-2" />
                                    <div className="font-bold">Email</div>
                                </div>
                                <div className="">
                                    {user ? user.auth.email : "-"}
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-orange-400">
                            <div className="py-4">
                                <div className="flex items-center ">
                                    <FcServices className="mr-2" />
                                    <div className="font-bold">Code</div>
                                </div>
                                <div className="">
                                    {user ? user.auth.code : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-orange-400">
                            <div className="py-4">
                                <div className="flex items-center ">
                                    <FcCopyright className="mr-2" />
                                    <div className="font-bold">Branch</div>
                                </div>
                                <div className="">
                                    {user ? user.auth.state.name : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-orange-400">
                            <div className="py-4">
                                <div className="flex  items-center">
                                    <FcLibrary className="mr-2" />
                                    <div className="font-bold">
                                        Public Bank Account
                                    </div>
                                </div>
                                <div className="">
                                    {user ? user.auth.bankDetails : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 row-gap-4 my-8">
                        <div className="lg:border border-orange-400 lg:mx-8">
                            <div className="py-4 flex lg:items-center flex-col">
                                <div className="flex  items-center">
                                    <FcSalesPerformance className="mr-2" />
                                    <div className="font-bold">
                                        Personal Sales
                                    </div>
                                </div>
                                <div className="">
                                    RM{" "}
                                    {user
                                        ? NumberFormatter.format(
                                              user.auth.personalSales
                                          )
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <div className="lg:border border-orange-400 lg:mx-8">
                            <div className="py-4 flex lg:items-center flex-col">
                                <div className="flex  items-center">
                                    <FcCurrencyExchange className="mr-2" />
                                    <div className="font-bold">Group Sales</div>
                                </div>
                                <div className="">
                                    RM {""}
                                    {user
                                        ? NumberFormatter.format(
                                              user.auth.groupSales
                                          )
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:border border-orange-400 lg:mx-8">
                        <div className="py-4 flex lg:items-center flex-col">
                            <div className="flex  items-center">
                                <FcSportsMode className="mr-2" />
                                <div className="font-bold">Sponsor</div>
                            </div>
                            <div className=" capitalize">
                                {user.auth.referral
                                    ? user.auth.referral.name
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="greyOpaqueBackground rounded-md w-full overflow-hidden lg:h-full">
                <div className="flex justify-center items-center h-full" onClick={() => console.log(user.auth)}>
                <img
    src={getImageSrc()}
    alt={user && user.auth && user.auth.state ? user.auth.state.name : ""}
/>
                </div>
            </div>
        </div>
    );
};

export default withUser(Dashboard);
