import React, { useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import queries from "../helpers/queries";
import { withUser } from "../Contexts/user";

const inputFields = [
    {
        name: "IC",
        type: "text",
    },
    {
        name: "Password",
        type: "password",
    },
];

const Login = ({ user, ...props }) => {
    const [inputs, setInputs] = useState({
        ic: "",
        password: "",
        checkbox: true,
    });

    const [login] = useLazyQuery(queries.LOGIN, {
        variables: {
            ic: inputs.ic,
            password: inputs.password,
        },
        onCompleted: ({ login: data }) => {
            user.auth.login(data, inputs.checkbox);
            user.auth.fetchUser();
            window.location.reload();
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    alert(graphQLError.message);
                    window.location.reload();
                }
            }

            if (networkError) {
                alert(networkError);
            }
        },
    });

    // useEffect(() => {
    //   if (result.data) {
    //     user.auth.login(result.data.login, inputs.checkbox);
    //   }
    // }, [result, inputs, user.auth])

    const handleInput = (value, name) => {
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const handleSignIn = async () => {
        login();
    };

    return (
        <section
            className="bg-fixed h-screen font-sans bg-cover"
            style={{ backgroundImage: `url("/hero.jpg")` }}
        >
            <div className="container h-full mx-auto flex justify-center items-center">
                <div className="panel bg-white w-104 p-6 rounded-md lg:m-0 m-8">
                    <div className="flex justify-center">
                        <img
                            className="min-h-14 max-h-14"
                            src={
                                "https://ik.imagekit.io/dnddecpho/dndream/dndlogocolor_cLZO36o6H-scaled_aZ4LTb2xf.webp?updatedAt=1741944231230"
                            }
                            alt={""}
                        />
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSignIn();
                        }}
                    >
                        {inputFields.map((item, i) => (
                            <div
                                key={i}
                                className={`${
                                    i !== inputFields.length - 1 ? "mb-2" : ""
                                }`}
                            >
                                <label className="block text-lynch text-xs font-semibold mb-1">
                                    {item.name}
                                </label>
                                <input
                                    value={inputs[item.name.toLowerCase()]}
                                    onChange={(e) =>
                                        handleInput(
                                            e.target.value,
                                            item.name.toLowerCase()
                                        )
                                    }
                                    className="p-2 text-lynch border border-black rounded text-base w-full"
                                    placeholder={item.name}
                                    type={item.type}
                                />
                            </div>
                        ))}
                        <button className="w-full rounded border text-center bg-darkblue text-white py-2 text-base font-semibold mt-4 hover:bg-black duration-200">
                            Sign In
                        </button>
                        <label className="block text-gray-600 font-semibold">
                            <input
                                onChange={() =>
                                    handleInput(!inputs.checkbox, "checkbox")
                                }
                                checked={inputs.checkbox}
                                className="mr-2 mt-4"
                                type="checkbox"
                            ></input>
                            <span className="text-sm">Remember Me</span>
                        </label>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default withUser(Login);
