import download from "downloadjs";
import _ from "lodash";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";

const createPdf = async (original) => {
    try {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(
            StandardFonts.TimesRoman
        );

        // Add a blank page to the document
        const page = pdfDoc.addPage();
        const fontSize = 20;
        const totalAmount =
            original.item.amount +
            original.item2.amount +
            original.item3.amount +
            original.item4.amount +
            original.item5.amount;

        // Get the width and height of the page
        const { height } = page.getSize();

        // Draw logo
        const jpgUrl = "https://ik.imagekit.io/dnddecpho/dndream/dndlogocolor_cLZO36o6H-scaled_aZ4LTb2xf.webp?updatedAt=1741944231230";
        const jpgImageBytes = await fetch(jpgUrl).then((res) =>
            res.arrayBuffer()
        );
        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
        const jpgDims = jpgImage.scale(0.04);
        page.drawImage(jpgImage, {
            x: 40,
            y: height - 7.5 * fontSize,
            width: jpgDims.width,
            height: jpgDims.height,
        });

        // Draw a string of text toward the top of the page
        page.drawText("D & DREAM PROPERTIES SDN BHD", {
            x: 150,
            y: height - 4 * fontSize,
            size: fontSize,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("(Company No: 1250810-W)", {
            x: 215,
            y: height - 5 * fontSize,
            size: 16,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`G-22, Boulevard 28, Jalan Pandan Perdana 3/10,`, {
            x: 185,
            y: height - 7 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Pandan Perdana, 55300 Kuala Lumpur`, {
            x: 205,
            y: height - 7.8 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Office Hotline: +603-9202 5937`, {
            x: 225,
            y: height - 8.6 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Payment Voucher", {
            x: 225,
            y: height - 10.6 * fontSize,
            size: fontSize,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Code: ${original.payee.code}`, {
            x: 60,
            y: height - 12.6 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Date: ${original.date}`, {
            x: 430,
            y: height - 12.6 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Name: ${_.startCase(_.toLower(original.payee.name))}`, {
            x: 60,
            y: height - 13.3 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Batch: ${original.batch}`, {
            x: 430,
            y: height - 13.3 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Payee: ${_.startCase(_.toLower(original.payee.name))}`, {
            x: 60,
            y: height - 14 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Method: ${_.upperCase(original.method)}`, {
            x: 60,
            y: height - 14.7 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Order No: ${original.orderNo}`, {
            x: 60,
            y: height - 15.4 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("No", {
            x: 115,
            y: height - 18 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Description", {
            x: 250,
            y: height - 18 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Total Amount (RM)", {
            x: 400,
            y: height - 18 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("1", {
            x: 120,
            y: height - 19 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Agents - Negotiator Comm", {
            x: 220,
            y: height - 19 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${NumberFormatter.format(totalAmount / 100 * 40)}`, {
            x: 430,
            y: height - 19 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("2", {
            x: 120,
            y: height - 20 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Agents - Negotiator Bonus", {
            x: 220,
            y: height - 20 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${NumberFormatter.format(totalAmount / 100 * 60)}`, {
            x: 430,
            y: height - 20 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 100,
                y: height - 20.5 * fontSize,
            },
            end: {
                x: 500,
                y: height - 20.5 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText(`${NumberFormatter.format(totalAmount)}`, {
            x: 425,
            y: height - 21.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 100,
                y: height - 21.75 * fontSize,
            },
            end: {
                x: 500,
                y: height - 21.75 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Commissions Detail", {
            x: 60,
            y: height - 23.5 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Agent - Negotiator Comm", {
            x: 60,
            y: height - 24.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Code", {
            x: 80,
            y: height - 25.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Case From", {
            x: 140,
            y: height - 25.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Project", {
            x: 240,
            y: height - 25.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("PV", {
            x: 335,
            y: height - 25.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("%", {
            x: 380,
            y: height - 25.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Amount (RM)", {
            x: 420,
            y: height - 25.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.payee.code}`, {
            x: 75,
            y: height - 26.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${_.startCase(_.toLower(original.payee.name))}`, {
            x: 130,
            y: height - 26.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${ original.batch.length > 10
            ? original.batch.substring(0, 10) + "..."
            : original.batch}`, {
            x: 230,
            y: height - 26.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${NumberFormatter.format(totalAmount)}`, {
            x: 320,
            y: height - 26.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("40", {
            x: 380,
            y: height - 26.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${NumberFormatter.format(totalAmount / 100 * 40)}`, {
            x: 435,
            y: height - 26.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 410,
                y: height - 27 * fontSize,
            },
            end: {
                x: 500,
                y: height - 27 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText(`${NumberFormatter.format(totalAmount / 100 * 40)}`, {
            x: 435,
            y: height - 27.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 410,
                y: height - 28 * fontSize,
            },
            end: {
                x: 500,
                y: height - 28 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Commissions Detail", {
            x: 60,
            y: height - 29 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Agent - Negotiator Bonus", {
            x: 60,
            y: height - 29.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Code", {
            x: 80,
            y: height - 30.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Case From", {
            x: 140,
            y: height - 30.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Project", {
            x: 240,
            y: height - 30.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("PV", {
            x: 335,
            y: height - 30.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("%", {
            x: 380,
            y: height - 30.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Amount (RM)", {
            x: 420,
            y: height - 30.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.payee.code}`, {
            x: 75,
            y: height - 31.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${_.startCase(_.toLower(original.payee.name))}`, {
            x: 130,
            y: height - 31.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${ original.batch.length > 10
            ? original.batch.substring(0, 10) + "..."
            : original.batch}`, {
            x: 230,
            y: height - 31.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${NumberFormatter.format(totalAmount)}`, {
            x: 320,
            y: height - 31.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("60", {
            x: 380,
            y: height - 31.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${NumberFormatter.format(totalAmount / 100 * 60)}`, {
            x: 435,
            y: height - 31.75 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 410,
                y: height - 32.25 * fontSize,
            },
            end: {
                x: 500,
                y: height - 32.25 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText(`${NumberFormatter.format(totalAmount / 100 * 60)}`, {                x: 435,
            y: height - 33 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 410,
                y: height - 33.25 * fontSize,
            },
            end: {
                x: 500,
                y: height - 33.25 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("For D & Dream Properties Sdn Bhd", {
            x: 60,
            y: height - 36.25 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 60,
                y: height - 38.75 * fontSize,
            },
            end: {
                x: 220,
                y: height - 38.75 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Account Department", {
            x: 60,
            y: height - 39.5 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 340,
                y: height - 38.75 * fontSize,
            },
            end: {
                x: 500,
                y: height - 38.75 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Received By", {
            x: 340,
            y: height - 39.5 * fontSize,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(
            pdfBytes,
            `"paymentvoucher-"${original.batch}`,
            "application/pdf"
        );
    } catch (err) {
        alert("Failed");
        console.log(err);
    }
};

export default createPdf;