import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import queries from "../helpers/queries";
import { useForm, Controller } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Table from "../Components/darkBasicTable";
import { useQuery } from "@apollo/react-hooks";
import useDimensions from "../Hooks/useDimensions";
import { FcSupport } from "react-icons/fc";
import Select from "react-select";
import _ from "lodash";
import { addBackList, removeBackList } from "../redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const statusOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Suspended",
        value: "suspended",
    },
    {
        label: "Risk Suspended",
        value: "risksuspended",
    },
];

const rankings = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Prime Leader",
        value: "5ec264312bde7cefbf119c4c",
    },
    {
        label: "Leader",
        value: "5ec2676c877421f075d0cbff",
    },
    {
        label: "Agent",
        value: "5ec5f937dfa89a2ccea9e9d9",
    },
];

const Teams = withRouter(() => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const size = useDimensions();

    const {
        handleSubmit: handleFilterSubmit,
        reset,
        control,
        errors,
    } = useForm({
        defaultValues: {
            name: "",
            referral: null,
        },
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const [filters, setFilters] = useState({
        name: "",
        referral: "",
        status: "active",
        rankings: "all",
    });

    const updateFilters = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            name: values.name !== "" ? values.name.id : filters.name,
            referral:
                values.referral !== null
                    ? values.referral.id
                    : filters.referral,
            status:
                values.status !== undefined
                    ? values.status.value
                    : filters.status,
            rankings:
                values.rankings !== undefined
                    ? values.rankings.value
                    : filters.rankings,
        }));

        setPaginationState((prev) => ({ ...prev, page: 1 }));
    };

    const columns = useMemo(() => {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        if (size.width > 650) {
            return [
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Ranking",
                    accessor: "progression",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Date of Birth",
                    accessor: "dob",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "Personal Sales",
                    accessor: "personalSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Group Sales",
                    accessor: "groupSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Upline",
                    accessor: "referral",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value.name}</div>
                    ),
                },
                {
                    Header: "Second Upline",
                    accessor: "secondReferral",
                    Cell: ({ value }) => (
                        <div className="capitalize">
                            {value ? value.name : ""}
                        </div>
                    ),
                },
                {
                    Header: "Find Downline",
                    id: "actions",
                    Cell: ({ row: { original } }) => {
                        return (
                            <div className="flex items-center justify-center">
                                <div
                                    className="cursor-pointer mr-2"
                                    onClick={async () => {
                                        dispatch(
                                            addBackList(
                                                filters.referral
                                                    ? filters.referral
                                                    : ""
                                            )
                                        );
                                        setFilters((prev) => ({
                                            ...prev,
                                            referral: original._id,
                                        }));

                                        setPaginationState((prev) => ({
                                            ...prev,
                                            page: 1,
                                        }));
                                    }}
                                >
                                    <FcSupport />
                                </div>
                            </div>
                        );
                    },
                },
            ];
        } else {
            return [
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Personal Sales",
                    accessor: "personalSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Group Sales",
                    accessor: "groupSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
            ];
        }
    }, [size, filters.referral, dispatch]);

    const [paginationState, setPaginationState] = useState({
        limit: 9999,
        page: 1,
    });

    const { loading: downlinesLoading, data: downlinesData } = useQuery(
        queries.FETCH_DOWNLINES,
        {
            variables: {
                limit: paginationState.limit,
                page: paginationState.page,
                name: filters.name,
                referral: filters.referral,
                status: filters.status,
                rank: filters.rankings,
            },
            fetchPolicy: "network-only",
            onCompleted: ({ fetchDownlines: { pagination } }) => {
                alert("Team Loading is Completed");
                setPaginationState(pagination);
            },
        }
    );

    return (
        <div
            className="min-h-screen bg-cover"
            style={{
                backgroundImage:
                    "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp')",
            }}
        >
            <div className="py-4 px-12 h-full">
                <div className="w-full lg:pr-4">
                    <form
                        className="grid grid-cols-1 lg:grid-cols-3 mb-4 col-gap-4 row-gap-4"
                        onSubmit={handleFilterSubmit(updateFilters)}
                    >
                        <div className="">
                            <Controller
                                as={<Select styles={filterSelectStyles} />}
                                name="name"
                                placeholder="Find One Agent"
                                control={control}
                                options={
                                    downlinesData
                                        ? downlinesData.fetchDownlines
                                              .uplineFilterList
                                        : []
                                }
                                value={filters.name}
                            />
                        </div>
                        <div className="">
                            <Controller
                                as={<Select styles={filterSelectStyles} />}
                                name="referral"
                                placeholder="Find Downline Team"
                                control={control}
                                options={
                                    downlinesData
                                        ? downlinesData.fetchDownlines
                                              .uplineFilterList
                                        : []
                                }
                                value={filters.upline}
                            />
                        </div>
                        <div className="">
                            <Controller
                                as={<Select styles={filterSelectStyles} />}
                                name="status"
                                placeholder={_.capitalize(filters.status)}
                                control={control}
                                options={statusOptions}
                                value={filters.status}
                            />
                        </div>
                        <div className="">
                            <Controller
                                as={<Select styles={filterSelectStyles} />}
                                name="rankings"
                                placeholder={
                                    filters.rankings ===
                                    "5ec5f937dfa89a2ccea9e9d9"
                                        ? "Agent"
                                        : filters.rankings ===
                                          "5ec2676c877421f075d0cbff"
                                        ? "Leader"
                                        : filters.rankings === "all"
                                        ? "All"
                                        : "Prime Leader"
                                }
                                control={control}
                                options={rankings}
                                value={filters.rankings}
                            />
                        </div>
                        <div></div>
                        <div className="flex flex-row justify-start items-center lg:justify-end text-center">
                            <div
                                className="font-semibold bg-specialpink hover:bg-iconpink hover:text-white py-2 px-3 lg:mr-1 rounded w-full w-1/2 mr-2 lg:w-1/3"
                                onClick={() => {
                                    const lastAgent =
                                        user.backlist.length === 0
                                            ? null
                                            : user.backlist[
                                                  user.backlist.length - 1
                                              ];

                                    dispatch(removeBackList());

                                    setFilters((prev) => ({
                                        ...prev,
                                        referral: lastAgent,
                                        upline: null,
                                    }));

                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: 1,
                                    }));
                                }}
                            >
                                Back
                            </div>
                            <button className="font-semibold bg-specialblue hover:bg-iconblue hover:text-white py-2 px-3 lg:mr-1 rounded w-full w-1/2 mr-2 lg:w-1/3">
                                Search
                            </button>
                            <div
                                className="font-semibold bg-specialgreen hover:bg-icongreen hover:text-white py-2 px-3 lg:mr-1 rounded w-full w-1/2 lg:mr-2 lg:w-1/3 cursor-pointer"
                                onClick={() => {
                                    setFilters((prev) => ({
                                        ...prev,
                                        name: null,
                                        upline: null,
                                    }));
                                    reset();
                                }}
                            >
                                Reset
                            </div>
                        </div>
                    </form>
                </div>
                <div className="py-4 table-header-actions flex justify-between items-start mt-8">
                <div className="w-full lg:pr-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4">
                    <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                    <img className="h-full w-16" src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-04_F6rcRq_oO.webp" alt="membericon"/>
                            <div className="ml-4 text-white text-sm">
                                Total Team Members:
                                <div className="text-icon text-2xl">
                                    {downlinesData
                                        ? downlinesData.fetchDownlines.totals
                                            ? downlinesData.fetchDownlines
                                                  .pagination.documents
                                            : "0"
                                        : "0"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="mt-6 table-header-actions flex justify-end items-start"></div>
                <Table
                    columns={columns}
                    data={
                        downlinesData ? downlinesData.fetchDownlines.data : []
                    }
                    updatePagination={setPaginationState}
                    loading={downlinesLoading}
                    pageCount={
                        downlinesData
                            ? downlinesData.fetchDownlines.pagination.totalPages
                            : 0
                    }
                    totalCount={
                        downlinesData
                            ? downlinesData.fetchDownlines.pagination.documents
                            : 0
                    }
                />
            </div>

        </div>
    );
});

export default withUser(Teams);
