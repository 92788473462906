import React, { useState, useMemo, useRef } from "react";
import queries from "../helpers/queries";
import { useForm } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Table from "../Components/darkBasicTable";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";

import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    BarChart,
    Bar,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const month =
            payload[0].payload.month === "1"
                ? "January"
                : payload[0].payload.month === "2"
                ? "February"
                : payload[0].payload.month === "3"
                ? "March"
                : payload[0].payload.month === "4"
                ? "April"
                : payload[0].payload.month === "5"
                ? "May"
                : payload[0].payload.month === "6"
                ? "June"
                : payload[0].payload.month === "7"
                ? "July"
                : payload[0].payload.month === "8"
                ? "August"
                : payload[0].payload.month === "9"
                ? "September"
                : payload[0].payload.month === "10"
                ? "October"
                : payload[0].payload.month === "11"
                ? "November"
                : payload[0].payload.month === "12"
                ? "December"
                : "0";
        return (
            <div className="custom-tooltip p-8 font-bold bg-black">
                <p
                    className="label p-2"
                    style={{ color: "#d8cbc9" }}
                >{`${month}-${payload[0].payload.year}`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#d8cbc9" }}
                >{`All : ${payload[0].payload.allRate}% (${payload[0].payload.allCases} Units)`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#7e6c4b" }}
                >{`Converted : ${payload[0].payload.conversionRate}% (${payload[0].payload.convertedCases} Units)`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#7e4b5d" }}
                >{`Cancelled : ${payload[0].payload.cancellationRate}% (${payload[0].payload.cancelledCases} Units)`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#6c4b7e" }}
                >{`Rejected : ${payload[0].payload.rejectionRate}% (${payload[0].payload.rejectedCases} Units)`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#5d7e4b" }}
                >{`Pending : ${payload[0].payload.pendingRate}% (${payload[0].payload.pendingCases} Units)`}</p>
            </div>
        );
    }

    return null;
};

const CustomLegendtip = () => {
    return (
        <ul>
            <div className="flex  justify-center items-center text-white">
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#d8cbc9",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#d8cbc9", listStyleType: "none" }}
                    key="allconversion"
                >
                    All Rate
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#7e6c4b",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#7e6c4b", listStyleType: "none" }}
                    key="conversion"
                >
                    Conversion Rate
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#7e4b5d",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#7e4b5d", listStyleType: "none" }}
                    key="cancellation"
                >
                    Cancellation Rate
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#6c4b7e",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#6c4b7e", listStyleType: "none" }}
                    key="rejected"
                >
                    Rejection Rate
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#5d7e4b",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className=""
                    style={{ color: "#5d7e4b", listStyleType: "none" }}
                    key="pending"
                >
                    Pending Rate
                </li>
            </div>
        </ul>
    );
};

const CustomToolUnit = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const month =
            payload[0].payload.month === "1"
                ? "January"
                : payload[0].payload.month === "2"
                ? "February"
                : payload[0].payload.month === "3"
                ? "March"
                : payload[0].payload.month === "4"
                ? "April"
                : payload[0].payload.month === "5"
                ? "May"
                : payload[0].payload.month === "6"
                ? "June"
                : payload[0].payload.month === "7"
                ? "July"
                : payload[0].payload.month === "8"
                ? "August"
                : payload[0].payload.month === "9"
                ? "September"
                : payload[0].payload.month === "10"
                ? "October"
                : payload[0].payload.month === "11"
                ? "November"
                : payload[0].payload.month === "12"
                ? "December"
                : "0";
        return (
            <div className="custom-tooltip p-8 font-bold bg-black">
                <p
                    className="label p-2"
                    style={{ color: "#d8cbc9" }}
                >{`${month}-${payload[0].payload.year}`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#d8cbc9" }}
                >{`All : ${payload[0].payload.allCases} Units`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#7e6c4b" }}
                >{`Converted : ${payload[0].payload.convertedCases} Units`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#7e4b5d" }}
                >{`Cancelled : ${payload[0].payload.cancelledCases} Units`}</p>
                <p
                    className="label p-2"
                    style={{ color: "#6c4b7e" }}
                >{`Rejected : ${payload[0].payload.rejectedCases} Units`}</p>

                <p
                    className="label p-2"
                    style={{ color: "#5d7e4b" }}
                >{`Pending : ${payload[0].payload.pendingCases} Units`}</p>
            </div>
        );
    }

    return null;
};

const CustomLegendUnit = () => {
    return (
        <ul>
            <div className="flex  justify-center items-center text-white">
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#d8cbc9",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#d8cbc9", listStyleType: "none" }}
                    key="allunit"
                >
                    All
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#7e6c4b",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#7e6c4b", listStyleType: "none" }}
                    key="conversionunit"
                >
                    Converted
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#7e4b5d",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#7e4b5d", listStyleType: "none" }}
                    key="cancellationunit"
                >
                    Cancelled
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#6c4b7e",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className="pr-8"
                    style={{ color: "#6c4b7e", listStyleType: "none" }}
                    key="rejectionunit"
                >
                    Rejected
                </li>{" "}
                <div
                    className="pr-2"
                    style={{
                        backgroundColor: "#5d7e4b",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        marginRight: "8px",
                    }}
                ></div>
                <li
                    className=""
                    style={{ color: "#5d7e4b", listStyleType: "none" }}
                    key="pendingunit"
                >
                    Pending
                </li>
            </div>
        </ul>
    );
};

const MonthlySDReport = ({ location }) => {
    const [totalConverted, setTotalConverted] = useState(0);
    const [totalCancelled, setTotalCancelled] = useState(0);
    const [totalRejected, setTotalRejected] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [totalSales, setTotalSales] = useState(0);

    const windowWidth = useRef(window.innerWidth);
    const windowHeight = useRef(window.innerHeight);

    useForm({
        defaultValues: {
            gallery: [{}],
            salesKit: "",
            spaPrice: "",
            rebate: "",
            fees: "",
            cashback: "",
            netPrice: "",
            status: "",
            bookingDate: moment(Date.now()).format("YYYY-MM-DD"),
            unitNumber: "",
            purchaserName: "",
            purchaserName2: "",
            purchaserName3: "",
            purchaserName4: "",
            purchaserName5: "",
            purchaserIc: "",
            purchaserIc2: "",
            purchaserIc3: "",
            purchaserIc4: "",
            purchaserIc5: "",
            purchaserContact: "",
            purchaserContact2: "",
            purchaserContact3: "",
            purchaserContact4: "",
            purchaserContact5: "",
            project: location.state
                ? location.state.project
                    ? location.state.project
                    : undefined
                : undefined,
        },
    });


    const [paginationState, setPaginationState] = useState({
        dir: "desc",
        by: "conversionRate",
    });

        //eslint-disable-next-line
    const [filters, setFilters] = useState({
        project: location.state
            ? location.state.project
                ? location.state.project
                : null
            : null,
        user: null,
        dateTo: moment(Date.now()).format("YYYY-MM-DD"),
        dateFrom: moment(Date.now()).subtract(1, "months").format("YYYY-MM-DD"),
        status: null,
        salesDirector: null,
        state: null,
        year: "2024",
    });


    const { data: performancesData, loading: casesLoading } = useQuery(
        queries.FETCH_MONTHLY_PERFORMANCE_AGENT,
        {
            fetchPolicy: "network-only",
            variables: {
                limit: 9999,
                page: 1,
                dir: paginationState ? paginationState.dir : undefined,
                by: paginationState ? paginationState.by : undefined,
                project: filters.project ? filters.project.value : undefined,
                user: filters.user ? filters.user : undefined,
                salesDirector: filters.salesDirector
                    ? filters.salesDirector
                    : undefined,
                year: filters.year ? filters.year : undefined,
                state: filters.state ? filters.state.value : undefined,
            },
            onCompleted: ({ fetchMonthlyPerformanceAgent: { data } }) => {
                for (const item of data) {
                    setTotalConverted(prevTotalConverted => prevTotalConverted + item.convertedCases);
                    setTotalCancelled(prevTotalCancelled => prevTotalCancelled + item.cancelledCases);
                    setTotalRejected(prevTotalRejected => prevTotalRejected + item.rejectedCases);
                    setTotalPending(prevTotalPending => prevTotalPending + item.pendingCases);
                    setTotalSales(prevTotalSales => prevTotalSales + item.allCases);
                }
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        }
    );

    const StatisticCard = ({ label, value }) => (
        <div className="">
            <div className={`flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border font-semibold items-center justify-center text-center ${label === "Total Sales" ? "bordersales" : label === "Total Converted" ? "borderconverted" : label === "Total Cancellation" ? "bordercancelled" : label === "Total Rejected" ? "borderrejected" : label === "Total Pending" ? "borderpending" : ""}`}>
                <div className="text-white lg:text-sm">
                    {label}: <div className="lg:text-2xl">{value}</div>
                </div>
            </div>
        </div>
    );

    const statistics = [
        { label: "Total Sales", value: totalSales },
        { label: "Total Converted", value: totalConverted },
        { label: "Total Cancellation", value: totalCancelled },
        { label: "Total Rejected", value: totalRejected },
        { label: "Total Pending", value: totalPending },
    ];

    const columns = useMemo(() => {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return [
            {
                Header: "Year",
                accessor: "year",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Month",
                accessor: "month",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Sales",
                accessor: "allCases",
                Cell: ({ value }) => <div>{value}</div>,
            },
            {
                Header: "Converted",
                id: "converted",
                Cell: ({ row: { original } }) => (
                    <div>
                        {original.convertedCases} 
                    </div>
                ),
            },
            {
                Header: "Cancellation",
                id: "cancelled",
                Cell: ({ row: { original } }) => (
                    <div>
                        {original.cancelledCases} 
                    </div>
                ),
            },
            {
                Header: "Rejected",
                id: "rejected",
                Cell: ({ row: { original } }) => (
                    <div>
                        {original.rejectedCases} 
                    </div>
                ),
            },
            {
                Header: "Pending",
                id: "pending",
                Cell: ({ row: { original } }) => (
                    <div>
                        {original.pendingCases} 
                    </div>
                ),
            },
            {
                Header: "Conversion Rate",
                id: "conversionRate",
                Cell: ({ row: { original } }) => (
                    <div>
                        {NumberFormatter.format(original.conversionRate)}
                         %
                    </div>
                ),
            },
            {
                Header: "Cancellation Rate",
                id: "cancellationRate",
                Cell: ({ row: { original } }) => (
                    <div>
                        {NumberFormatter.format(original.cancellationRate)}
                         %
                    </div>
                ),
            },
            {
                Header: "Rejected Rate",
                id: "rejectedRate",
                Cell: ({ row: { original } }) => (
                    <div>
                        {NumberFormatter.format(original.rejectionRate)}
                         %
                    </div>
                ),
            },
            {
                Header: "Pending Rate",
                id: "pendingRate",
                Cell: ({ row: { original } }) => (
                    <div>
                        {NumberFormatter.format(original.pendingRate)}
                         %
                    </div>
                ),
            },
        ];
        
    }, []);

    return (
        <div             className="min-h-screen bg-cover"
        style={{
            backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp')",
        }}>
            <div className="py-4 px-12">
                <div className="table-header-actions flex justify-between items-start mt-8">
                    <div className="w-full lg:pr-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 mb-4 col-gap-4">
                            {statistics.map((stat, index) => (
                                <StatisticCard
                                    key={index}
                                    label={stat.label}
                                    value={stat.value}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={
                        performancesData
                            ? performancesData.fetchMonthlyPerformanceAgent.data
                            : []
                    }
                    updatePagination={setPaginationState}
                    paginationState={paginationState}
                    loading={casesLoading}
                    pageCount={0}
                    totalCount={0}
                />
                                <div className="justify-center flex py-16 bg-black">
                    <BarChart
                        stroke="#000000"
                        fill="#000000"
                        width={windowWidth.current / 1.5}
                        height={windowHeight.current / 1.5}
                        data={
                            performancesData
                                ? performancesData.fetchMonthlyPerformanceAgent
                                      .data
                                : []
                        }
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                        <XAxis dataKey="month" stroke="#fff" type="category" />
                        <YAxis stroke="#fff" />
                        <Tooltip content={<CustomToolUnit />} />
                        <Legend content={<CustomLegendUnit />} />

                        <Bar
                            dataKey="convertedCases"
                            stackId="a"
                            fill="#FFE565"
                            label
                        />
                        <Bar
                            dataKey="cancelledCases"
                            stackId="a"
                            fill="#C24641"
                            label
                        />
                        <Bar
                            dataKey="rejectedCases"
                            stackId="a"
                            fill="#E5A3FF"
                            label
                        />
                        <Bar
                            dataKey="pendingCases"
                            stackId="a"
                            fill="#C3FD88"
                            label
                        />
                        <Bar
                            dataKey="allCases"
                            stackId="b"
                            fill="#eeeeee"
                            label
                        />
                    </BarChart>
                </div>
                <div className="justify-center flex py-16 bg-black">
                    <LineChart
                        width={windowWidth.current / 1.5}
                        height={windowHeight.current / 1.5}
                        data={
                            performancesData
                                ? performancesData.fetchMonthlyPerformanceAgent
                                      .data
                                : []
                        }
                        stroke="#fff"
                        fill="#fff"
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                    >
                        <CartesianGrid stroke="#fff" strokeDasharray="3 3" />
                        <XAxis dataKey="month" stroke="#fff" />
                        <YAxis stroke="#fff" />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={<CustomLegendtip />} />
                        <Line
                            type="monotone"
                            dataKey="allCases"
                            stroke="#eeeeee"
                            strokeWidth={5}
                            label
                        />
                        <Line
                            type="monotone"
                            dataKey="convertedCases"
                            stroke="#FFE565"
                            strokeWidth={5}
                            label
                        />
                        <Line
                            type="monotone"
                            dataKey="cancelledCases"
                            stroke="#C24641"
                            strokeWidth={5}
                            label
                        />
                        <Line
                            type="monotone"
                            dataKey="rejectedCases"
                            stroke="#E5A3FF"
                            strokeWidth={5}
                            label
                        />
                        <Line
                            type="monotone"
                            dataKey="pendingCases"
                            stroke="#C3FD88"
                            strokeWidth={5}
                            label
                        />
                    </LineChart>
                </div>
            </div>
        </div>
    );
};

export default withUser(MonthlySDReport);
