import React, { useMemo, useState } from "react";
import queries from "../helpers/queries";
import { withUser } from "../Contexts/user";
import Table from "../Components/darkBasicTable";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import Select from "react-select";
import month from "../month";

const DownlineReport = () => {
    const [totalDownlines, setTotalDownlines] = useState(0);
    const [totalSalesUnit, setTotalSalesUnit] = useState(0);
    const [totalGroupSalesUnit, setTotalGroupSalesUnit] = useState(0);
    const [totalConvertUnit, setTotalConvertUnit] = useState(0);
    const [totalGroupConvertUnit, setTotalGroupConvertUnit] = useState(0);


    const [filters, setFilters] = useState({
        // month : (moment().month() + 1).toString(),
        month: "1",
        dateTo: moment(Date.now()).format("YYYY-MM-DD"),
        dateFrom: moment(Date.now()).subtract(1, "weeks").format("YYYY-MM-DD"),
    });
    const [report, setReport] = useState("Personal Report");

    const [paginationState, setPaginationState] = useState({
        page: 1,
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        []
    );

    const columns = useMemo(
        () => {
            const NumberFormatter = new Intl.NumberFormat("en-GB", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
    
            return [
                {
                    Header: "Downline",
                    accessor: "user",
                    Cell: ({ value }) => (
                        <div style={{ textTransform: "capitalize" }}>{value}</div>
                    ),
                },
                {
                    Header: "Sales Unit",
                    accessor: "personalBookingSales",
                },
                {
                    Header: "Sales Net GDV",
                    accessor: "personalNetGdv",
                    Cell: ({ value }) => (
                        <div>RM {NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Sales Net GDV Percentage",
                    accessor: "netGdvPercentage",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)} %</div>
                    ),
                },
                {
                    Header: "Convert Units",
                    accessor: "convertUnit",
                },
                {
                    Header: "Convert Net GDV",
                    accessor: "convertNetGdv",
                    Cell: ({ value }) => (
                        <div>RM {NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Convert Percentage",
                    accessor: "convertPercentage",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)} %</div>
                    ),
                },
            ];
        },
        []
    );

    const groupColumns = useMemo(
        () => {
            const NumberFormatter = new Intl.NumberFormat("en-GB", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return [
                {
                    Header: "Downline",
                    accessor: "user",
                    Cell: ({ value }) => (
                        <div style={{ textTransform: "capitalize" }}>{value}</div>
                    ),
                },
                {
                    Header: "Group Sales Unit",
                    accessor: "groupBookingSales",
                },
                {
                    Header: "Group Sales Net GDV",
                    accessor: "groupPersonalNetGdv",
                    Cell: ({ value }) => (
                        <div>RM {NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Group Net GDV Percentage",
                    accessor: "groupNetGdvPercentage",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)} %</div>
                    ),
                },
                {
                    Header: "Group Convert Units",
                    accessor: "groupConvertUnit",
                },
                {
                    Header: "Group Convert Net GDV",
                    accessor: "groupConvertNetGdv",
                    Cell: ({ value }) => (
                        <div>RM {NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Group Convert Percentage",
                    accessor: "groupConvertPercentage",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)} %</div>
                    ),
                },
              
            ]
        },
        []
    );

    const 
        { loading: downlineSalesReportLoading, data: downlineSalesReportData }
    = useQuery(queries.FETCH_DOWNLINE_SALES_REPORT_AGENT, {
        fetchPolicy: "network-only",
        variables: {
            by: paginationState.by ? paginationState.by : undefined,
            dir: paginationState.dir ? paginationState.dir : undefined,
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo,
            year: "2024",
            month: filters.month,
        },
        onCompleted: (data) => {
            if (data.fetchDownlineSalesReportAgent) {
                const downlineData = data.fetchDownlineSalesReportAgent.data;
                
                // Set total downlines
                setTotalDownlines(downlineData.length);
                
                // Calculate totals
                let totalSalesUnit = 0;
                let totalConvertUnit = 0;
                let totalGroupSalesUnit = 0;
                let totalGroupConvertUnit = 0;

                
                downlineData.forEach((item) => {
                    totalSalesUnit += item.personalBookingSales;
                    totalConvertUnit += item.convertUnit;
                    totalGroupSalesUnit += item.groupBookingSales;
                    totalGroupConvertUnit += item.groupConvertUnit;
                });
        
                // Update state with calculated totals
                setTotalSalesUnit(totalSalesUnit);
                setTotalConvertUnit(totalConvertUnit);
                setTotalGroupSalesUnit(totalGroupSalesUnit);
                setTotalGroupConvertUnit(totalGroupConvertUnit);
            }
        },
        
    });

    return (
        <div className="min-h-screen bg-cover"
        style={{
            backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp')",
        }}>
  <div className="py-4 px-6 lg:px-12">
            <div className="table-header-actions flex flex-wrap justify-start lg:justify-end items-start mb-5">
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 col-gap-4 row-gap-4">
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={[
                                    {
                                        value: "Personal Report",
                                        label: "Personal Report",
                                    },
                                    {
                                        value: "Group Report",
                                        label: "Group Report",
                                    },
                                ]}
                                placeholder={report}
                                onChange={(val) => setReport(val.value)}
                            />
                        </div>
                        <div className="">
                                <Select
                                    styles={filterSelectStyles}
                                    isSearchable={true}
                                    options={month}
                                    placeholder="Month"
                                    value={{
                                        value: filters.month,
                                        label: filters.month,
                                    }}
                                    onChange={(val) =>
                                        setFilters((prev) => ({
                                            ...prev,
                                            month: val.value,
                                        }))
                                    }
                                />
                            </div>
                    </div>
                </div>

            </div>
            <div className="table-header-actions flex justify-between items-start mt-8">
                    <div className="w-full lg:pr-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 col-gap-4">
                            <div className="mb-4">
                                <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                <img className="h-full w-16" src="https://venti-fans.com/wp-admin/upload.php?item=1005" alt="totaldownline" />
                                    <div className="ml-4 text-white text-sm">
                                        Total Downlines:{" "}
                                        <div className="text-sm lg:text-2xl">
                                            {totalDownlines}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-4">
                                <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                <img className="h-full w-16" src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-01_0X6DfeObd.webp" alt="totalsales" />
                                    <div className="ml-4 text-white text-sm">
                                        Total Cases:
                                        <div className="text-sm lg:text-2xl">
                                            {totalSalesUnit}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                <img className="h-full w-16" src="https://ik.imagekit.io/dnddecpho/SSR/D&D_SystemImages_Icon-08_Ig1C9Ats4h.png?updatedAt=1719631002561" alt="totalconvert" />
                                    <div className="ml-4 text-white text-sm">
                                        Total Convert Units:
                                        <div className="text-sm lg:text-2xl">
                                            {totalConvertUnit}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                <img className="h-full w-16" src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-04_F6rcRq_oO.webp" alt="totalgroupsales"/>
                                    <div className="ml-4 text-sm">
                                         Total Group Sales Units:
                                        <div className="text-sm lg:text-2xl">
                                            {totalGroupSalesUnit}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                                <img className="h-full w-16" src="https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_Icon-06_VoI03VJDv.webp" alt="totalgroupconvert"/>
                                    <div className="ml-4 text-sm">
                                         Total Group Convert Units:
                                        <div className="text-sm lg:text-2xl">
                                            {totalGroupConvertUnit}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {report === "Personal Report" && (
                <div className="mb-16">
                    <div className="text-gray-900 font-bold py-4 text-2xl lg:text-3xl text-center bg-gradient capitalize">
                        Personal Report
                    </div>
                    <Table
                        columns={columns}
                        data={
                            downlineSalesReportData ? 
                            downlineSalesReportData.fetchDownlineSalesReportAgent 
                                ? downlineSalesReportData
                                      .fetchDownlineSalesReportAgent.data
                                : [] : []
                        }
                        updatePagination={setPaginationState}
                        paginationState={paginationState}
                        loading={downlineSalesReportLoading}
                    />
                </div>
            )}
            {report === "Group Report" && (
                <div className="">
                    <div className="text-black font-bold py-4 text-3xl text-center bg-group-gradient capitalize">
                        Group Report
                    </div>
                    <Table
                        columns={groupColumns}
                        data={
                            downlineSalesReportData
                            ? downlineSalesReportData.fetchDownlineSalesReportAgent
                                ? downlineSalesReportData
                                      .fetchDownlineSalesReportAgent.data
                                : []
                                : []
                        }
                        loading={downlineSalesReportLoading}
                    />
                </div>
            )}
        </div>
        </div>
      
    );
};

export default withUser(DownlineReport);
