import React, { useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import { Link, withRouter } from "react-router-dom";
import { withUser } from "../Contexts/user";
import Routes from "../Routes";
import { FcUndo } from "react-icons/fc";

const Header = withRouter(({ user, location }) => {
    const [menuMobile, setMenuMobile] = useState(false);
    return (
        <div
            className={`${
                menuMobile ? "z-50 absolute inset-0 w-full h-full" : ""
            }`}
        >
            <div className={`relative z-20 bg-black justify-center flex`}>
                <div className="w-1/3">
                    <Hamburger
                        toggled={menuMobile}
                        toggle={() => setMenuMobile((prev) => !prev)}
                        color={"#fff"}
                    />
                </div>
                <div className="text-white w-1/2 md:my-2 flex justify-center items-center">
                    <Link to="/">
                        <img
                            src={
                                "https://venti-fans.com/wp-content/uploads/2025/03/logo_tJIyIW_B9.webp"
                            }
                            alt={""}
                        />
                    </Link>
                </div>
                <div className="text-white w-1/3 flex justify-end items-center"></div>
            </div>
            {menuMobile && (
                <div className="pt-12 fixed inset-0 w-full h-full bg-black bg-opacity-90 z-10 overflow-y-auto">
                    {Routes.map(({ Icon, ...route }, i) => {
                        // eslint-disable-next-line
                        if (route.hidden) return;

                        if (user) {
                            if (user.auth) {
                                if (user.auth.ranking) {
                                    if (
                                        route.primepath &&
                                        user.auth.ranking.name !==
                                            "prime leader"
                                    )
                                        // eslint-disable-next-line
                                        return;

                                    if (
                                        route.pdpath &&
                                        user.auth.director !== "PD"
                                    )
                                        // eslint-disable-next-line
                                        return;

                                    if (
                                        route.sdpath &&
                                        user.auth.director !== "SD"
                                    )
                                        // eslint-disable-next-line
                                        return;

                                    if (
                                        route.coordinatorpath &&
                                        user.auth.director !== "coordinator"
                                    )
                                        // eslint-disable-next-line
                                        return;
                                }
                            }
                        }

                        return (
                            <Link
                                className={`flex hover:text-iconblue ${
                                    location.pathname === route.path
                                        ? "bg-specialblue text-black"
                                        : "text-cool-gray"
                                } hover:bg-specialblue px-3 py-2 items-center text-lg`}
                                onClick={() => setMenuMobile(false)}
                                key={i}
                                to={route.path}
                            >
                                <div className="mr-2">
                                    <Icon />
                                </div>
                                <div className="text-base md:text-3xl">
                                    {route.name}
                                </div>
                            </Link>
                        );
                    })}
                    <div
                        onClick={user.auth.logout}
                        className="cursor-pointer md:text-3xl flex text-cool-gray hover:text-iconblue hover:bg-specialblue p-3 items-center"
                    >
                        <FcUndo className="mr-2" /> Logout
                    </div>
                </div>
            )}
        </div>
    );
});

export default withUser(Header);
