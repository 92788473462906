import React from "react";
import Routes from "../Routes";
import { Link, withRouter } from "react-router-dom";
import { withUser } from "../Contexts/user";
import { FcUndo } from "react-icons/fc";

const Sidebar = withRouter(({ user, location }) => {
    if (user.auth.userId === null) {
        window.location.href = "/login";
    }
    return (
        <div className="bg-black min-h-screen text-white min-w-48 w-2/12 flex-shrink-0 hidden lg:block">
            <div className="hidden lg:block sidebar h-full greyOpaqueBackground ">
        
            <div className="flex justify-center items-center">
    {/* Border Image Container */}
    <div
        className={`relative z-10 p-2 w-48 h-48 ${
            user?.auth?.ranking
                ? user.auth.smcDate
                    ? "bg-smc-border-image"
                    : user.auth.pmcDate
                    ? "bg-pmc-border-image"
                    : user.auth.emcDate
                    ? "bg-emc-border-image"
                    : user.auth.omcDate
                    ? "bg-omc-border-image"
                    : "bg-border-image"
                : "bg-border-image"
        } overflow-hidden rounded-lg flex justify-center items-center`}
    >
        {/* Image Wrapper */}
        <div className="relative w-32 h-32">
            <img
                src={
                    user?.auth?.image
                        ? user.auth.image
                        : "https://venti-fans.com/wp-content/uploads/2025/03/avatar_DmSKRB3ql.webp"
                }
                alt="Main Content"
                className="w-full h-full object-contain"
            />
        </div>
    </div>
</div>




                {Routes.map(({ Icon, ...route }, i) => {
                    // eslint-disable-next-line
                    if (route.hidden) return;

                    if (user) {
                        if (user.auth) {
                            if (user.auth.ranking) {
                                if (
                                    route.primepath &&
                                    user.auth.ranking.name !== "prime leader"
                                )
                                    // eslint-disable-next-line
                                    return;

                                if (route.pdpath && user.auth.director !== "PD")
                                    // eslint-disable-next-line
                                    return;

                                if (route.sdpath && user.auth.director !== "SD")
                                    // eslint-disable-next-line
                                    return;

                                if (
                                    route.coordinatorpath &&
                                    user.auth.director !== "coordinator"
                                )
                                    // eslint-disable-next-line
                                    return;
                            }
                        }
                    }

                    return (
                        <Link
                            className={`flex font-semibold ${
                                location.pathname === route.path
                                    ? "text-orange-400 font-black underline"
                                    : "text-cool-gray"
                            } hover:underline underline-offset p-2 items-center`}
                            key={i}
                            to={route.path}
                        >
                            <div className="mr-2">
                                <Icon />
                            </div>
                            <div className="text-sm">{route.name}</div>
                        </Link>
                    );
                })}
                <div
                    onClick={user.auth.logout}
                    className="cursor-pointer text-sm flex hover:underline underline-offset p-2 items-center font-semibold"
                >
                    <FcUndo className="mr-2" /> Logout
                </div>
            </div>
        </div>
    );
});

export default withUser(Sidebar);
